import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CSS/Loading.css";
import { SetSMSLeft, SetpricePerSMS, Setsender, SetshowModal } from "../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";

import CircleCheck from "../img/circle-checked.svg"
import Circle from "../img/circle-x.svg"
import Discount from "../img/discount.svg"
import Icon from "../img/icon.svg"
import PinStart from "../img/pin-start.svg"
import Pin from "../img/pin.svg"
import Route from "../img/route.svg"
import { SetAllOrders, SetsellecTedOrder } from "../store/UserReducer";
export default function SingleOrder({ element, b1, b2, b3, func }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const SMSLeft = useSelector((e) => e.UserReducer.SMSLeft);
  const SMSnotyfication = useSelector((e) => e.UserReducer.SMSnotyfication);
  const Sender = useSelector((e) => e.UserReducer.sender);
  const URL = useSelector((e) => e.UserReducer.url);
  const [SMSText, setSMSText] = useState('');
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setSMSText(UserInfo.editable_texts.sms_order_notification)
    }
  }, [UserInfo]);
  //console.log(element);
  const dispatch = useDispatch();
  const [Height, setHeight] = useState("");
  const random = () => {
    let cod =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    ////console.log("Wyrandowany kod:" + cod);
    return cod;
  };
  useEffect(() => {
    setHeight(document.getElementById("getHeight").clientHeight);
  }, []);
  const pushNotification = (device_id, subject, text) => {
    console.log(device_id)
    console.log(subject)
    console.log(text)
    let date = new Date();
    let date_time = date.getTime();
    if (device_id !== undefined && device_id !== '' && subject !== undefined && subject !== '' && text !== undefined && text !== '') {
      axios
        .post(
          `${URL}/notifications/order_notification.php`,
          {
            to_who: device_id,
            title: subject,
            body: text,
            sentTime: date_time,
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((response) => {
          console.log(response)

        })
        .catch((err) => {
          console.log(err)
        });
    }
  }
  const [OrderState, setOrderState] = useState("Order");
  const changeState = () => {
    setOrderState("Options");
  };
  const back = () => {
    setOrderState("Order");
  };

  const step1 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "3",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data === "OK") {
          if (SMSLeft > 0 && SMSnotyfication === true) {
            sendSMS();

          }
          func();
          if (element.device_id !== undefined) {
            pushNotification(element.device_id, 'Cześć', 'Twoje zamówienie jest w przygotowaniu!')
          }

        }
      })
      .catch((err) => {
        console.log(err.response);
        setOrderState("Order");
      });
  };

  const step2 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "4",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data === "OK") {
          console.log("tak");
          if (element.device_id !== undefined) {
            pushNotification(element.device_id, 'Cześć', 'Twoje zamówienie jest w drodze!')
          }
          func();
        }
      })
      .catch((err) => {
        //console.log(err.response);
        setOrderState("Order");
      });
  };
  const step3 = () => {
    setOrderState("Loading");
    axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/order/${element.id}`,
        {
          id: element.id,
          restaurants_order_id: element.restaurants_order_id,
          progress: "5",
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        if (response.status === 200 && response.data === "OK") {
          //console.log("tak");
          //  pushNotification(element.device_id,'STEP3','STEP3')
          func();
        }
      })
      .catch((err) => {
        //console.log(err.response);
        setOrderState("Order");
      });
  };
  const sendSMS = () => {
    let control = random();
    axios
      .post(
        `https://api.hostedsms.pl/FullApi/Smses`,
        {
          Phone: ["48" + element.phone_number],
          Message: SMSText,
          Sender: Sender,
          TransactionId: control,
          Priority: "3",
          FlashSms: false,
        },
        {
          headers: {
            "User-Agent": "Fiddler",
            Authorization:
              "Basic ZmVlZHdlYi5jb250YWN0QGdtYWlsLmNvbTpLYXdhc2FraTA4",
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (
          response.data.ErrorMessage === undefined &&
          response.data.MessageIds !== undefined
        ) {
          //console.log(response);
          smsSpend();
        } else {
          console.log(response);
          //smsSpend();
          // setTimeout(function () {}, 1000);
        }
      })

      .catch((err) => {
        console.log(err.response);
      });
  };
  const smsSpend = () => {
    axios
      .put(
        `${SERVER_URL}/${ResteurantCode}/sms_sent `,
        {
          quantity: 1,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response);
        sms_left();
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const sms_left = () => {
    axios
      .get(`${SERVER_URL}/${ResteurantCode}/sms_left `, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);
        SetSMSLeft(response.data.sms_left, dispatch);
        SetpricePerSMS(response.data.sms_price, dispatch);
        Setsender(response.data.sender, dispatch);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  if (OrderState === "Order") {
    return (
      <div
        className="px-1 w-100 mb-1 float-left"

      >
        <div
          style={{
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            height: '100%!important',
            border: '1px solid #dadee2',
            borderRadius: '5px'

          }}
          className={
            element.is_place
              ? "px-3 pointer py-3 row mx-0"
              : "px-3 pointer border border-info py-3 row mx-0"
          }
          id="getHeight"
        >
          <div className="col-12 px-0 fs1 Font15px">

            <div class="span3" >
              {element.progress === "2" ?
                <div className="ColorIcon d-flex justify-content-center align-items-center mr-1"
                  style={{
                    backgroundColor: '#f2994a'
                  }}
                ><i class="demo-icon icon-clock text-white mx-0"
                >&#xe802;</i></div>
                : element.progress === "3" ?
                  <i class="demo-icon icon-reserve text-white "
                    style={{
                      backgroundColor: '#56ccf2'
                    }}
                  >&#xe806;</i>
                  : element.progress === "4" ? <i class="demo-icon icon-box text-white"
                    style={{
                      backgroundColor: '#27ae60'
                    }}
                  >&#xe800;</i> : element.progress === "5" ?
                    <i class="demo-icon icon-clipboard-tick text-white"
                      style={{
                        backgroundColor: '#eb5757'
                      }}
                    >&#xe801;</i> : ''}
              {element.is_internal === true && element.is_place === false ? <i class="demo-icon icon-gps mr-1">&#xe804;</i>
                : element.is_internal === false && element.is_place === false ? <i class="demo-icon icon-location mr-1">&#xe805;</i>
                  : element.is_internal === false && element.is_place === true ? <i class="demo-icon icon-routing mr-1">&#xe807;</i> : ''}
              {element.payment_type === "1" ? <i class="demo-icon icon-tick-square mr-1">&#xe808;</i>
                : element.payment_type === "2" ? <i class="demo-icon icon-close-square mr-1">&#xe803;</i> : ''}

              {element.promotion_code !== "" ? <i class="demo-icon icon-verify mr-5">&#xe809;</i> : ''}
              {element.is_place === false ? <span class="i-name mr-5">Stół: 55</span> : ''}

              <span class="i-name float-right"

              > {element.ordered_time + ', ' + element.ordered_date}</span>

            </div>






          </div>
          <div className="col-12 px-0 py-1 "
            onClick={() => {
              SetshowModal(true, dispatch)
              SetsellecTedOrder(element, dispatch)
            }}
          >

            {element.meal_options.map((element, index) => {

              if (element.additions.length === 0) {
                return <p className="mb-1"><span className="Lite" style={{
                  fontWeight: '600',
                  fontsize: '10px'
                }}> {element.name} </span> </p>;
              } else if (element.additions.length > 0) {
                let additions = '';
                element.additions.map((elemant) => {
                  additions = additions + `<span class=" "> ${elemant.name}</span>`
                }

                );

                return (
                  <>
                    <p className="mb-0">
                      <span className="Lite" style={{
                        fontWeight: '600'
                      }}> {element.name}</span>
                    </p>
                    <p style={{
                      color: '#6c757d',
                      fontWeight: '500'
                    }} className="mb-0 pl-2"  dangerouslySetInnerHTML={{ __html: additions }}></p>
                    {/* <p className=" Thin Font10px ml-3 mb-2"> + Dodatki (kliknij aby zoabczyć)</p>*/}
                  </>
                );
              }

            })}


          </div>

          <div className="col-12 px-0 fs1 border-top py-2"
            onClick={() => {
              SetshowModal(true, dispatch)
              SetsellecTedOrder(element, dispatch)
            }}
          >
            <p className="mb-2">
              <span className="mr-3 Lite " style={{
                fontWeight: '600',
                fontSize: '17px',
                color:'#6c757d'
              }}>
                Zamówienie: {element.id}
              </span>
              <span className="Lite float-right" style={{
                fontWeight: '600',
                fontSize: '17px'
              }}>
                {element.to_pay} zł
              </span>
            </p>
            <button type="button" class="btn btn-primary w-100  text-center " style={{
               paddingBottom: '2px',
               paddingTop: '2px',
               fontWeight: '600',
              }}>  <span>Przygotowywane</span> <i class="demo-icon icon-verify ml-0">&#xe809;</i></button>
          </div>


        </div>
      </div>
    );
  } else if (OrderState === "Options") {
    return (
      <blockquote
        className="px-0 border-bottom my-3 min-h d-flex align-items-center"
        style={{}}
      >
        <div className="row mx-0">
          <button
            type="button"
            className={
              b1
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step1}
          >
            W przygotowaniu
          </button>
          <button
            type="button"
            className={
              b2
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step2}
          >
            W doręczniu
          </button>
          <button
            type="button"
            className={
              b3
                ? "btn mb-3 w-100 btn-warning upper Bold"
                : "btn mb-3 w-100 btn-warning upper Bold d-none"
            }
            onClick={step3}
          >
            Zakończ
          </button>
          <button
            type="button"
            className="btn mb-3 w-100 btn-outline-warning upper Bold"
            onClick={back}
          >
            X
          </button>
        </div>
      </blockquote>
    );
  } else if (OrderState === "Loading") {
    return (
      <blockquote
        className="px-0 border-bottom my-3 min-h d-flex align-items-center"
        style={{}}
      >
        <div className="bodyLoad2">
          <div class="loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>{" "}
      </blockquote>
    );
  }
}
