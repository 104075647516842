import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { useSelector } from "react-redux";
export default function EmailCampainMenagment({ e, func }) {
  const URL = useSelector((e) => e.UserReducer.url);
  const [Display, setDisplay] = useState("none");
  const send = (id) => {
    axios
      .get(`${URL}/sqlite/send_email_campaign.php/?id=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response);
        setDisplay("flex");
        setTimeout(function () {
          func();
          setDisplay("none");
        }, 1500);
      })

      .catch((err) => {
        //  console.log(err.response);
      });
  };
  const deleteCampain = (id) => {
    axios
      .get(`${URL}/sqlite/delete_email_campaign.php/?id=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // console.log(response);
        func();
      })

      .catch((err) => {
        //  console.log(err.response);
      });
  };
  return (
    <div className="w-full ">
      <div className="flex border-bottom justify-between justify-items-start  ">
        <div className="flex mb-2 mt-2 ">
          <div className="text-base w-36 mr-3  font-weight:400 d-flex break-words   ">
            {e.name}
          </div>
          <div className="text-base w-36 mr-3 pl-3   font-weight:400 d-flex break-words ">
            {e.subject}
          </div>
          <div className="text-base w-52 mr-3 pr-2    font-weight:400 d-flex break-words ">
            {ReactHtmlParser(e.text)}
          </div>
        </div>

        <div className="mb-2 mt-2  ">
          {e.status === "0" ? (
            <>
              <div className="flex flex-col items-center lg:flex-row">
                <button
                  type="button"
                  className=" mx-2 h-8 items-center mb-1  px-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => send(e.id)}
                >
                  Zrealizuj
                </button>
                <button
                  type="button"
                  className=" h-8 items-center px-3 w-24    text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => deleteCampain(e.id)}
                >
                  Usuń
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col justify-center items-center lg:flex-row">
                <button
                  type="button"
                  className=" mx-2 h-10 items-center px-1 mb-1   border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-darkIndigo bg-lightIndigo hover:bg-indigo hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => send(e.id)}
                >
                  Zrealizuj ponownie
                </button>
                <button
                  type="button"
                  className="h-8 items-center px-1 w-24 text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => deleteCampain(e.id)}
                >
                  Usuń
                </button>
              </div>
            </>
          )}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "green",
              display: Display,
            }}
            className=" justify-content-center align-items-center Bold text-white"
          >
            Wysłano
          </div>
        </div>
      </div>
    </div>
  );
}
