/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from 'react'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

export default function DeleteModal({ isDeleted, cancel, city }) {
	const [open, setOpen] = useState(true)
	// const [isDeleted, setIsDeleted] = useState(false)

	const cancelButtonRef = useRef(null)

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-10 '
				initialFocus={cancelButtonRef}
				onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed z-10 inset-0 overflow-y-auto'>
					<div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							enterTo='opacity-100 translate-y-0 sm:scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 translate-y-0 sm:scale-100'
							leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
							<Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
								<div>
									<div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full'>
										<svg
											width='48'
											height='48'
											viewBox='0 0 48 48'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z'
												fill='#FEE2E2'
											/>
											<path
												d='M24 21V23M24 27H24.01M17.0718 31H30.9282C32.4678 31 33.4301 29.3333 32.6603 28L25.7321 16C24.9623 14.6667 23.0378 14.6667 22.268 16L15.3398 28C14.57 29.3333 15.5322 31 17.0718 31Z'
												stroke='#DC2626'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<div className='mt-3 text-center sm:mt-5'>
										<Dialog.Title
											as='h3'
											className='text-lg leading-6 font-medium text-gray-900'>
											Usuwanie strefy dostaw
										</Dialog.Title>
										<div className='mt-2'>
											<p className='text-sm text-gray-500'>
												Czy na pewno chcesz usunąć strefe {city}?
											</p>
										</div>
									</div>
								</div>
								<div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
									<button
										type='button'
										className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo text-base font-medium text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2  sm:text-sm'
										onClick={() => {
											setOpen(false)
											isDeleted(true)
										}}>
										Usun
									</button>
									<button
										type='button'
										className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm'
										onClick={() => {
											setOpen(false)
											cancel()
										}}
										ref={cancelButtonRef}>
										Anuluj
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
