import React, { useState, useEffect } from "react";
import FileBase64 from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import ToogleSwitch from "../ToggleSwitch/ToggleSwitch";
import axios from "axios";
import { SetMenuCategory, SetMenuToEdit } from "../../../store/MenuReducer";
import {SetCoupons} from "../../../store/UserReducer";
import MultiSelect from "../MultiSelect/MultiSelect";
import EditSelectedMealOptionForm from "../EditSelectedMealOptionForm/EditSelectedMealOptionForm";

export default function EditMealForm(props) {
  const RestaurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const URL = useSelector((e) => e.UserReducer.url);

  const UserInfo = useSelector((e) => e.UserReducer.allData);

  const [Icons_legend_title, setIcons_legend_title] = useState("");
  const [Icon_1_title, setIcon_1_title] = useState("");
  const [Icon_2_title, setIcon_2_title] = useState("");
  const [Icon_3_title, setIcon_3_title] = useState("");
  const [Icon_4_title, setIcon_4_title] = useState("");
  const [Icon_5_title, setIcon_5_title] = useState("");
  const [Icon_6_title, setIcon_6_title] = useState("");
  const [Img1, setImg1] = useState("");
  const [Img2, setImg2] = useState("");
  const [Img3, setImg3] = useState("");
  const [Img4, setImg4] = useState("");
  const [Img5, setImg5] = useState("");
  const [Img6, setImg6] = useState("");

  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setIcons_legend_title(UserInfo.editable_texts.icons_legend_title);
      setIcon_1_title(UserInfo.editable_texts.icon_1_title);
      setIcon_2_title(UserInfo.editable_texts.icon_2_title);
      setIcon_3_title(UserInfo.editable_texts.icon_3_title);
      setIcon_4_title(UserInfo.editable_texts.icon_4_title);
      setIcon_5_title(UserInfo.editable_texts.icon_5_title);
      setIcon_6_title(UserInfo.editable_texts.icon_6_title);
console.log('ustawiam tablicę tagów ')
      setOptionsTags([
        { value:  Icon_1_title, label: Icon_1_title},
        { value:  Icon_2_title, label: Icon_1_title},
        { value:  Icon_3_title, label: Icon_1_title},
        { value:  Icon_4_title, label: Icon_1_title},
        { value:  Icon_4_title, label: Icon_1_title},
        { value:  Icon_5_title, label: Icon_1_title},
        { value:  Icon_6_title, label: Icon_6_title},

      ])
    }
 
    if (UserInfo.img !== undefined) {
      setImg1(UserInfo.img.icon1);
      setImg2(UserInfo.img.icon2);
      setImg3(UserInfo.img.icon3);
      setImg4(UserInfo.img.icon4);
      setImg5(UserInfo.img.icon5);
      setImg6(UserInfo.img.icon6);
    }
  });
 

  const [MealName, setMealName] = useState("");
  const [Description, setDescription] = useState("");
  const [PhotoUrl, setPhotoUrl] = useState("");
  const [IsPromoted, setIsPromoted] = useState(false);
  const [IsActive, setIsActive] = useState(false);
  const [EncodedPhoto, setEncodedPhoto] = useState(false);
  const [IsHighlighted, setIsHighlighted] = useState(false);
  const [MealBaseIndex, setMealBaseIndex] = useState(0);
  const [MealOptionIndexes, setMealOptionsIndexes] = useState([]);
  const [SizeAdjustable, setSizeAdjustable] = useState(false);
  const [MealBase, setMealBase] = useState({});
  const [SaveButtonText, setSaveButtonText] = useState("Zapisz zmiany");
  const [Active, setActive] = useState(false);
  const [ComunicateText, setComunicateText] = useState("");
  const [activeStatusButton, setactiveStatusButton] = useState("Pokaż");


  const onSelect = () => {
    console.log("zadarzenie select")

  }
  const [OptionsTags,setOptionsTags] = useState([{ value: "22", label: "22" }])
  const [selOpt,setSleOpt] = useState(null);
  const  [OptionSelected, setOptionSelected] = useState([])
  


  const dispatch = useDispatch();
  const Code = useSelector((e) => e.UserReducer.resteurantCode);

  const activeStatusChange = () =>{
    if(IsActive){
      setIsActive(false)
      setactiveStatusButton("Pokaż")
    }else{
      setIsActive(true)
      setactiveStatusButton("Ukryj")
    }

  }

  const getMenuToEdit = async () => {
    await axios
      .get(`${SERVER_URL}/${Code}/edit_menu`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        if (SetMenuToEdit(response.data, dispatch)) {
          // //console.log("ooocholera");
        }
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };


  const getAllMenu = async () => {
    await axios
      .get(`${URL}/get_menu.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //  //console.log(response.data);
        
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };

  useEffect(() => 
    {
      const timer = setTimeout(()=>setComunicateText(""),1500)
      return () => clearTimeout(timer)
    }
    ,[ComunicateText])
 

  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  console.log("To jest nasze menu");
  console.log(Menu);

  function EditMealBase(meal_base) {
    meal_base.name = MealName;
    meal_base.ingredients = Description;
    meal_base.photo_url = PhotoUrl;
    // to pole należy dodać do API
    meal_base.is_promoted = IsPromoted;
    meal_base.is_highlighted = IsHighlighted;
    meal_base.is_active = IsActive;
    meal_base.encoded_photo = EncodedPhoto;
    // not implemented on frontend yet
    //menu[category_id][index].menu_position = MenuPosition

    return meal_base;
  }
  

  function SendEditedMeal(meal_base) {
    let new_meal_base = EditMealBase(meal_base);
    console.log(new_meal_base);
    setMealBase(new_meal_base);
    setSaveButtonText("Czekaj");
    setActive(true);
    axios
      .put(`${SERVER_URL}/${RestaurantCode}/meal_options`, new_meal_base, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setComunicateText("Pomyślnie zedytowano ");
        getAllMenu();
        getMenuToEdit()
     

          
        }
        setSaveButtonText("Zapisz zmiany");
        setActive(false);
      })
      .catch((err) => {
        console.log(err.response);
        setComunicateText("Coś poszło nie tak...Spróbuj ponownie ");
        setSaveButtonText("Zapisz zmiany");
        setActive(false);
      });

      getAllMenu();
      getMenuToEdit()
     
  }

 

  function AddNewOption() {
    let option = {
      id: null,
      meal_base: props.dishId,
      name: "",
      size: "",
      kcal: "",
      price: null,
      is_active: true,
    };
    Menu.meal_categories[props.categoryId].meal_bases[
      MealBaseIndex
    ].meal_options.push(option);
    // console.log(Menu.meal_categories[category_id].meal_bases[MealBaseIndex].meal_options)
    GetMealOptionsIndexes(MealBaseIndex);
  }

  const removeMeal = (id) => {
    //console.log(id);
    axios
      .delete(`${SERVER_URL}/${ResteurantCode}/meal_base/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          window.location.reload();
          // setActive(true);
        }
      })
      .catch((err) => {
        // setActive(true);
        //console.log(err.response);
      });
  };

  const onChangePromotedButton = () => {
    setIsPromoted(!IsPromoted);
  };
  const onChangeHighlightedButton = () => {
    setIsHighlighted((IsHighlighted) => !IsHighlighted);
  };
  const onChangeSizeAdjustableButton = () => {
    setSizeAdjustable((SizeAdjustable) => !SizeAdjustable);
  };
  useEffect(() => {
    let index = Menu.meal_categories[props.categoryId].meal_bases.findIndex(
      function (meal_base) {
        return meal_base.id == props.dishId;
      }
    );
    GetMealOptionsIndexes(index);
    setMealBaseIndex(index);
    setMealName(props.dish);
    setDescription( Menu.meal_categories[props.categoryId].meal_bases[index].ingredients  );
    setIsActive(Menu.meal_categories[props.categoryId].meal_bases[index].is_active)
    setIsPromoted(Menu.meal_categories[props.categoryId].meal_bases[index].is_promoted)
    setIsHighlighted(Menu.meal_categories[props.categoryId].meal_bases[index].is_highlighted)

    

    console.log(Menu);
  }, [Menu, props.category, props.dish]);

  function GetMealOptionsIndexes(meal_base_index) {
    //console.log(meal_base_index);
    let indexes_array = [];
    for (
      let i = 0;
      i <
      Menu.meal_categories[props.categoryId].meal_bases[meal_base_index]
        .meal_options.length;
      i++
    ) {
      indexes_array.push(i);
    }
    setMealOptionsIndexes(indexes_array);
    return indexes_array;
  }
  return (
    <div>
      <div className=" w-912px border-b border-lightgray mx-3 my-0">
        <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i pl-3 ">
          {props.category} {props.category && "/"} {props.dish}
        </p>
      </div>

      {props.category && (
        <div>
          <div className="flex border-lightgray mt-2 border-b">
            <div className="w-50 border-r border-lightgray  ">
              <div className="text-darkgray font-bold text-base pt-3 font-weight: 300 font-i pl-1 mx-3 my-0 mb-3">
                Informacje ogólne
              </div>
              <label className="mb-0 mx-3 text-gray-500">Nazwa dania</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm  border-borderGray rounded-md"
                  placeholder=""
                  value={MealName}
                  onChange={(e) => {
                    setMealName(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>

              <label className="mb-0 mx-3 text-gray-500">Opis</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <textarea
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={Description}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) =>setDescription(e.target.value)}
                />
              </div>
              <label className="mb-0 mx-3 text-gray-500">Oznaczenia</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm  border-borderGray rounded-md"
                  placeholder=""
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
              <div class="mb-3 relative rounded-md mx-3 z-50 ">
                  <MultiSelect
                    options={ OptionsTags} // Options to display in the dropdown
                    selectedOptions= {selOpt}
                    selectedOptionsChange = { (e) => { setSleOpt(e)}}
                     />
              </div>

              <div class="input-group mb-10 mt-2   opacity-0 z-10 ">
                <FileBase64
                  multiple={false}
                  onDone={File.bind()}
                  className=""
                />
              </div>
              <div className="flex items-start relative -top-16 left-0 mx-3">
                <button className="mr-2 border-gray border-2 px-2 rounded-md">
                  {" "}
                  Dodaj logo
                </button>
                <p className="text-gray-500">JPG lub PNG max 2mb</p>
              </div>
              <div className="mx-3 ">
                <div className=" mb-4 ">
                  <ToogleSwitch
                    name="Wyróżnij"
                    description="Wróżmnienie pozycji kolorem przewodnim menu"
                    enabled={IsHighlighted}
                    onChange={onChangeHighlightedButton}
                  />
                </div>

                <div className="mb-4">
                  <ToogleSwitch
                    name="Promuj"
                    description='Pozycja trafi na samą górę menu do sekcji "promowane"'
                    enabled={IsPromoted}
                    onChange={onChangePromotedButton}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-darkgray font-bold text-base mb-0 ">
                      Konieczny wybór składników
                    </p>
                    <p className="text-sm text-gray-500 mt-0">
                      Klient decyduje o składnikach np. rodzaj mięsa
                    </p>
                  </div>
                  <div className=" bg-lightgray rounded-full h-4 w-53px">
                    <p className="text-darkgray   text-xs align-middle text-center ">
                      Wkrótce
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-50 ml-3">
              <div className="text-darkgray font-bold text-base pt-3 font-weight: 300 font-i pl-1 mb-3">
                Warianty cenowe
              </div>
              <ToogleSwitch
                name="Rozmiary wariantów"
                description="Pozwala określić rozmiary i/lub kalorie dania"
                enabled={SizeAdjustable}
                onChange={onChangeSizeAdjustableButton}
              />

              <div className="flex justify-between items-center mt-3">
                <div>
                  <p className="text-darkgray font-bold text-base mb-0 ">
                    Płatne opakowania
                  </p>
                  <p className="text-sm text-gray-500 mt-0">
                    Dodatkowo płatne opakowania na wynos
                  </p>
                </div>
                <div className=" bg-lightgray rounded-full h-4 w-53px">
                  <p className="text-darkgray   text-xs align-middle text-center ">
                    Wkrótce
                  </p>
                </div>
              </div>

              {MealOptionIndexes.map((element) => (
                <EditSelectedMealOptionForm
                  category_id={props.categoryId}
                  meal_base_id={props.dishId}
                  meal_option_index={element}
                  refresh_indexes={GetMealOptionsIndexes}
                  sizeAdjustable={SizeAdjustable}
                /> // id={element} func={set} disabled={NameDisable}/>
              ))}

              <div className="mt-3">
                <button className="text-sm font-medium" onClick={AddNewOption}>
                  + Dodaj wariant
                </button>
              </div>
            </div>
          </div>

          <div className=" w-976px flex justify-between bg-backgroundGray h-100">
            <div className="flex mt-3">
              <button
                className=" mx-3 h-8 items-center px-1  text-sm leading-4 font-medium rounded-md   hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => removeMeal(props.dishId)}
              >
                Usuń
              </button>
              <button 
              className="h-8 items-center px-1   text-sm leading-4 font-medium rounded-md   hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={activeStatusChange }
              >
              {  IsActive ? 'Ukryj ': 'Pokaż'}
              </button>
            </div>
            <div className="mt-3">
              {ComunicateText}
              <button 
              className=" mr-5 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() =>SendEditedMeal(Menu.meal_categories[props.categoryId].meal_bases[MealBaseIndex])}
              >
                Zapisz
              </button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}
