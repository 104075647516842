import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import ActiveZones from '../ActiveZones/ActiveZones'
import './style.css'

export default function NewDeliveryZoneForm({
	active,
	setShowModal,
	activeDelivery,
	setChoosenDeliveryType
}) {
	const ResteurantCode = useSelector(e => e.UserReducer.resteurantCode)
	const SERVER_URL = useSelector(e => e.UserReducer.server_URL)
	const URL = useSelector(e => e.UserReducer.url)

	const [Active, setActive] = useState(true)
	const [ActiveDelivery, setActiveDelivery] = useState(false)
	const [Text, setText] = useState('Dodaj strefę')
	const [ComunicateText, setComunicateText] = useState('')
	const [Delivery, setDelivery] = useState(null)
	const [ActiveFields, setActiveFields] = useState(false)
	const [deliveryPrice, setDeliveryPrice] = useState('');
	const [City, setCity] = useState('')
	const [FreeAbove, setFreeAbove] = useState('')
	const [Price, setPrice] = useState('')
	const [addedZone, setAddedZone] = useState(false)
	const [freeDelivery, setFreeDelivery] = useState('')
	const [pickUp, setPickUp] = useState(false)

	useEffect(() => {
		setPickUp(active)
		setActiveDelivery(activeDelivery)
		getDeliveryPlaces()
	}, [])

	useEffect(() => {
		setPickUp(active)
	}, [active])

	useEffect(() => {
		if(activeDelivery === false){
			setFreeDelivery('');
		} else {
			setFreeDelivery(' ');
		}	
	}, [activeDelivery])

	const getDeliveryPlaces = () => {
		const res = axios
			.get(`${SERVER_URL}/${ResteurantCode}/delivery_places`, {
				'Content-Type': 'application/json',
			})
		const resData = res.then((response) => response.data);
		resData.then((data) => {
			setDelivery(data.places);
		});
		return resData;
	}
	const addDelivery = () => {
		if (
			City !== '' &&
			deliveryPrice !== '' &&
			(freeDelivery !== '' || activeDelivery === true)
		) {
			setAddedZone(!addedZone)
			setActive(false)
			setText('Czekaj')
			let json = {
				name: City,
				delivery_price: parseInt(deliveryPrice),
				free_above: activeDelivery ? null : freeDelivery,
			}

			axios
				.post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
					'Content-Type': 'application/json',
				})
				.then(response => {
					if (response.status === 201 && response.statusText === 'Created') {
						setActive(true)
						setText('Dodaj strefę')
						setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
						setCity('');
						setDeliveryPrice('');
						setFreeDelivery('');
						getDeliveryPlaces();
					}
				})
				.catch(err => {})
		} else if (pickUp == true) {
			setPickUp(false)
			setAddedZone(!addedZone)

			let arr = Object.values(Delivery)
			let arrayRes = []
			arr.map(el => {
				if (el.name.indexOf('Odbiór osobisty') > -1) {
					arrayRes.push(el.name.indexOf('Odbiór osobisty') > -1)
				}
			})
			console.log(arrayRes, 'result')

			if (arrayRes.length === 0) {
				setText('Czekaj')
				let json = {
					name: 'Odbiór osobisty',
					delivery_price: 0,
					free_above: 0,
					is_place: true,
				}
				axios
					.post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
						'Content-Type': 'application/json',
					})
					.then(response => {
						if (response.status === 201 && response.statusText === 'Created') {
							setActive(true)
							setText('Dodaj strefę')
							setPickUp(false)
							setComunicateText('Dodano! Możesz teraz dodać kolejną strefę')
							getDeliveryPlaces();
						}
					})
					.catch(err => {})
			} else {
				setPickUp(false)
				setComunicateText('Odbiór osobisty już istnieje!')
			}
		} else {
			setComunicateText('Uzupełnij wszytkie pola')
		}
	}

	const sendPrice1 = (x, value) => {
		let p = value
		setFreeAbove(p)
	}

	const sendPrice2 = (x, value) => {
		let p = value
		setPrice(p)
	}
	return (
		<>
			<form className='w-full '>
				<div className='grid grid-cols-5 gap-4 '>
					<div className='col-span-2  mb-6 md:mb-0 grow'>
						<label
							htmlFor='price'
							className='block text-sm font-normal text-gray'>
							Nazwa Miejscowości
						</label>
						<div className='mt-1 relative rounded-md shadow-sm'>
							<input
								type='text'
								name='price'
								id='price'
								placeholder=''
								value={City}
								disabled={pickUp}
								onChange={e => {
									setCity(e.target.value)
								}}
								className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md'
								aria-describedby='price-currency'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'></div>
						</div>
					</div>

					<div className='col-span-1  mb-6 md:mb-0'>
						<label
							htmlFor='price'
							className='block text-sm font-normal text-gray'>
							Koszt Dostawy
						</label>
						<div className='mt-1 relative rounded-md shadow-sm'>
							<input
								type='number'
								id='basic-url'
								value={deliveryPrice}
								disabled={pickUp}
								onChange={e => {
									setDeliveryPrice(e.target.value);
								}}
								className='focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 pl-7 pr-12 sm:text-sm border-borderGray rounded-md'
								aria-describedby='basic-addon3'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
								<span className='text-gray-500 sm:text-sm' id='price-currency'>
									PLN
								</span>
							</div>
						</div>
					</div>
					<div className=' col-span-1 mb-6 md:mb-0'>
						<label htmlFor='price' className='block text-sm text-gray'>
							Darmowa Dostawa
						</label>
						<div className='mt-1 relative rounded-md shadow-sm '>
							<input
								className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 pl-7 pr-12 sm:text-sm border-borderGray rounded-md ${activeDelivery ? 'bg-gray-100 placeholder-gray-200' : ''}`}
								type='number'
								value={freeDelivery}
								name='price'
								disabled={activeDelivery}
								placeholder='od'
								onChange={e => {
									setFreeDelivery(e.target.value);
								}}
								id='basic-url'
								aria-describedby='price-currency'
							/>
							<div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
								<span className='text-gray-500 sm:text-sm' id='price-currency'>
									PLN
								</span>
							</div>
						</div>
					</div>
					<div className='col-span-1 mt-4 pt-1 mb-6 md:mb-0 grow'>
						<button
							type='button'
							className='inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							// className=' focus:ring-indigo-500 focus:border-indigo-500 block  pl-7 pr-12 sm:text-sm border-borderGray mx-auto rounded-md inline-flex items-center  float-right px-4 py-2 border border-transparent text-basefont-medium rounded-md shadow-sm text-white bg-indigo hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							onClick={() => addDelivery()}
							disabled={!Active}>
							{Text}
						</button>
					</div>
				</div>
			</form>
			<p className={`mt-2 ${ComunicateText === 'Uzupełnij wszytkie pola' ? 'text-red-600' : ''}`}>{ComunicateText}</p>

			<p className='text-lg font-md font-bold mt-4'>Aktywne Strefy Dostaw</p>
			<ActiveZones updateState={addedZone} delivery={Delivery} getDeliveryPlaces={getDeliveryPlaces} setChoosenDeliveryType={setChoosenDeliveryType}/>
		</>
	)
}
