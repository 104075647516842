import React, { useState, useEffect } from 'react'
import Cookies from "js-cookie";
import {
    SetToggled
} from "../store/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
export function NavNew({history}) {
    const Toggled = useSelector((e) => e.UserReducer.toggled);
    const dispatch = useDispatch();
 const Logout=(e)=>{
  Cookies.remove("IsLoggedIn")
  e.preventDefault();
  history.push("/login");
 }

return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-1 static-top shadow py-0 px-1 d-none">
          <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    Pokaż filtry
  </button>
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3"
                onClick={() => {
                    if (Toggled === '') {
                        SetToggled('toggled', dispatch)
                    } else if (Toggled === 'toggled') {
                        SetToggled('', dispatch)
                    }

                }}
            >
                <i className="fa fa-bars" />
            </button>
 <ul className="navbar-nav ml-auto">
  {/* Nav Item - Search Dropdown (Visible Only XS) */}
  <li className="nav-item dropdown no-arrow d-sm-none">
    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fas fa-search fa-fw" />
    </a>
    {/* Dropdown - Messages */}
    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
      <form className="form-inline mr-auto w-100 navbar-search">
        <div className="input-group">
          <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i className="fas fa-search fa-sm" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </li>
  {/* Nav Item - Alerts */}

  {/* Nav Item - Messages */}
  
  <div className="topbar-divider d-none d-sm-block" />
  {/* Nav Item - User Information */}
  <li className="nav-item dropdown no-arrow">
    <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span className="mr-2 d-none d-lg-inline text-gray-600 small">FeedWeb</span>
      <img className="img-profile rounded-circle" src="img/undraw_profile.svg" />
    </a>
    {/* Dropdown - User Information */}
    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
     
      <a className="dropdown-item" href="" data-toggle="modal" data-target="#logoutModal"
      onClick={(e)=>{Logout(e)}}
      >
        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
        Logout
      </a>
    </div>
  </li>
</ul>

        </nav>
    )
}
export default withRouter(NavNew);