import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Cupon from "./Cupon";
import NavNew from "./NavNew";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
export default function Cupons() {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);

  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [EditableMenu, setEditableMenu] = useState(null);

  useEffect(() => {
    if (Menu !== null) {
      setEditableMenu(Menu.meal_categories);
    }
  }, [Menu]);
  useEffect(() => {
    getCupons();
  }, []);
  const [Cupons, setCupons] = useState(null);
  const getCupons = () => {
    axios
      .get(`${SERVER_URL}/${ResteurantCode}/coupons`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          //console.log(response.data);
          setCupons(null);
          setCupons(response.data);
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const removeCupons = (element) => {
    //console.log(element);

    axios
      .post(
        `${SERVER_URL}/${ResteurantCode}/coupon/1`, // '1' jest wartościa nie uzywaną (musi być bo dawid nie umie programować)
        {
          coupon_ids: element,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          getCupons();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [Code, setCode] = useState("");
  const [Percent, setPercent] = useState("");
  const ValidCode = (val) => {
    setCode(val);
  };
  const ValidPercent = (val) => {
    setPercent(val);
  };
  const [CuponList, setCuponList] = useState({});
  const CuponsMealsList = (id) => {
    let arr = CuponList;
    if (arr[id] === undefined) {
      arr[id] = id;
    } else {
      delete arr[id];
    }
    // //console.log(arr);
    //  //console.log(id);
    setCuponList(arr);
  };
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj kupon");
  const [ComunicateText, setComunicateText] = useState("");
  const addCoupon = () => {
    if (Code !== "" && Percent !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        code: Code,
        percent: Percent,
        meal: CuponList,
      };

      //  //console.log(json);
      axios
        .post(`${SERVER_URL}/${ResteurantCode}/coupons`, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          //console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //console.log(response);
            clear();
            setActive(true);
            setText("Dodaj kupon");
            setComunicateText("Dodano! Możesz teraz dodać kolejny kupon");
            getCupons();
          }
        })
        .catch((err) => {
          //console.log(err.response);
          setActive(true);
          setText("Dodaj kupon");
          setComunicateText(
            "Uzupełnij wszytkie pola i przypisz kupon do potrawy zaznaczjac ją. Pamiętaj, kody kuponów nie mogą sie powtarzać"
          );
        });
    } else {
      setActive(true);
      setText("Dodaj kupon");
      setComunicateText(
        "Uzupełnij wszytkie pola i przypisz kupon do potrawy zaznaczjac ją."
      );
    }
  };
  const clear = () => {
    setCode("");
    setPercent("");
  };
  if (EditableMenu !== null) {
    return (
      <>
        <HeaderNew />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <SettingsNavNew
              tabs={[
                { name: "Kupony rabatowe", path: "/campaigns/cupons" },
                { name: "Kampanie e-mail", path: "/campaigns/email" },
                { name: "Kampanie SMS", path: "/campaigns/sms" },
                {
                  name: "Apilkacja mobilna ",
                  path: "/campaigns/pushnotification",
                },
                {
                  name: "Baza klientów",
                  path: "/campaigns/customermanagement",
                },
              ]}
            />
            <NavNew />
            <div className="container-fluid ">
              <div
                className="container-fluid display  px-0 position-relative"
                style={{ background: "white", minHeight: window.innerHeight }}
              >
                <div className="max-w-7xl mx-auto  pl-5 pt-1 pr-5">
                  <div className=" mt-2">
                    <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                      Utwórz nowy kupon
                    </p>
                  </div>
                  <label>Kod kuponu </label>
                  <div class="mb-3 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      className="upper   focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Code}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        ValidCode(e.target.value.toUpperCase());
                      }}
                    />
                  </div>

                  <label>% zniżki</label>
                  <div class="mb-3 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Percent}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        ValidPercent(e.target.value);
                      }}
                    />
                  </div>
                  <label>Rodzaj potrawy </label>

                  <div className="  ">
                    {Object.keys(EditableMenu).map((element) => (
                      <>
                        <div className=" mt-3 mx-0 px-0   ">
                          <span className=" ">
                            {EditableMenu[element].name}
                          </span>
                        </div>
                        <div className="flex justify-between ">
                          {Object.keys(EditableMenu[element].meal_bases).map(
                            (x) => {
                              if (
                                Object.keys(
                                  EditableMenu[element].meal_bases[x]
                                    .meal_options
                                ).length === 1
                              ) {
                                return (
                                  <div class="custom-control custom-checkbox mr-sm-2 my-2">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id={
                                        EditableMenu[element].meal_bases[x]
                                          .name +
                                        EditableMenu[element].meal_bases[x].id +
                                        EditableMenu[element].meal_bases[x].name
                                      }
                                      onChange={() =>
                                        CuponsMealsList(
                                          EditableMenu[element].meal_bases[x]
                                            .meal_options[0].id
                                        )
                                      }
                                    />
                                    <label
                                      class="custom-control-label"
                                      for={
                                        EditableMenu[element].meal_bases[x]
                                          .name +
                                        EditableMenu[element].meal_bases[x].id +
                                        EditableMenu[element].meal_bases[x].name
                                      }
                                    >
                                      {EditableMenu[element].meal_bases[x].name}
                                    </label>
                                  </div>
                                );
                              } else {
                                {
                                  return (
                                    <div className="my-2 ">
                                      {Object.keys(
                                        EditableMenu[element].meal_bases[x]
                                          .meal_options
                                      ).map((y) => (
                                        <div class="custom-control custom-checkbox mr-sm-2 ">
                                          <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            id={
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].name +
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].id +
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].name
                                            }
                                            onChange={() =>
                                              CuponsMealsList(
                                                EditableMenu[element]
                                                  .meal_bases[x].meal_options[y]
                                                  .id
                                              )
                                            }
                                          />
                                          <label
                                            class="custom-control-label"
                                            for={
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].name +
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].id +
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].name
                                            }
                                          >
                                            <span>
                                              {
                                                EditableMenu[element]
                                                  .meal_bases[x].name
                                              }
                                              {" - "}
                                            </span>

                                            {
                                              EditableMenu[element].meal_bases[
                                                x
                                              ].meal_options[y].name
                                            }
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                }
                              }
                            }
                          )}
                        </div>
                      </>
                    ))}

                    <div className="flex justify-between">
                      <div></div>
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => addCoupon()}
                        disabled={!Active}
                      >
                        {" "}
                        {Text}
                      </button>
                    </div>

                    <div className=" mt-2">
                      <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                        Aktywne kupony
                      </p>
                    </div>

                    <div className="flex   border-bottom   align-items-center ">
                      <div className="text-base font-bold w-40 text-left mx-1  font-weight:300 d-flex  align-items-center">
                        Kod kuponu
                      </div>

                      <div className=" text-base font-bold w-24 text-left mx-1  font-weight:300 d-flex px-1 align-items-center">
                        % zniżki
                      </div>
                      <div className="text-base font-bold w-44 text-left mx-1 font-weight:300 d-flex  align-items-center">
                        Danie
                      </div>
                    </div>
                    {Cupons !== null
                      ? Object.keys(Cupons).map((e) => {
                          return (
                            <Cupon func={removeCupons} element={Cupons[e]} />
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return "";
  }
}
