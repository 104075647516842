import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import EditSelectedMealOption from "./EditSelectedMealOption";
import FileBase64 from "react-file-base64";

export default function EditSelectedMeal({ category_id, meal_base_id }) {
  const RestaurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const [MealName, setMealName] = useState("");
  const [Ingreadients, setIngreadients] = useState("");
  const [PhotoUrl, setPhotoUrl] = useState("");
  const [EncodedPhoto, setEncodedPhoto] = useState("");
  const [IsPromoted, setIsPromoted] = useState(null);
  const [IsActive, setIsActive] = useState(null);
  const [MealBase, setMealBase] = useState({});
  

  const [MealBaseIndex, setMealBaseIndex] = useState(0);
  const [MealOptionIndexes, setMealOptionsIndexes] = useState([]);

  const [SaveButtonText, setSaveButtonText] = useState("Zapisz zmiany");
  const [AddOptionText, setAddOptionText] = useState("Dodaj opcje");

  const [ComunicateText, setComunicateText] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (Menu !== null) {
      let index = Menu.meal_categories[category_id].meal_bases.findIndex(
        function (meal_base) {
          return meal_base.id == meal_base_id;
        }
      );
      setMealBaseIndex(index);
      GetMealOptionsIndexes(index);
      setMealName(Menu.meal_categories[category_id].meal_bases[index].name);
      setIngreadients(
        Menu.meal_categories[category_id].meal_bases[index].ingredients
      );
      setPhotoUrl(
        Menu.meal_categories[category_id].meal_bases[index].photo_url
      );
      setIsPromoted(
        Menu.meal_categories[category_id].meal_bases[index].is_promoted
      );
      setIsActive(
        Menu.meal_categories[category_id].meal_bases[index].is_active
      );
      setMealBase(Menu.meal_categories[category_id].meal_bases[index]);
    }
  }, [Menu, category_id, meal_base_id]);

  const File = (files) => {
    setEncodedPhoto(files.base64);
    setPhotoUrl(files.name);
  };

  function EditMealBase(meal_base) {
    meal_base.name = MealName;
    meal_base.ingredients = Ingreadients;
    meal_base.photo_url = PhotoUrl;
    meal_base.is_promoted = IsPromoted;
    meal_base.is_active = IsActive;
    meal_base.encoded_photo = EncodedPhoto;
    // not implemented on frontend yet
    //menu[category_id][index].menu_position = MenuPosition

    return meal_base;
  }

  function AddNewOption() {
    let option = {
      id: null,
      meal_base: meal_base_id,
      name: "",
      size: "",
      kcal: "",
      price: null,
      is_active: true,
    };
    Menu.meal_categories[category_id].meal_bases[
      MealBaseIndex
    ].meal_options.push(option);
    // console.log(Menu.meal_categories[category_id].meal_bases[MealBaseIndex].meal_options)
    GetMealOptionsIndexes(MealBaseIndex);
  }

  function GetMealOptionsIndexes(meal_base_index) {
    //console.log(meal_base_index);
    let indexes_array = [];
    for (
      let i = 0;
      i <
      Menu.meal_categories[category_id].meal_bases[meal_base_index].meal_options
        .length;
      i++
    ) {
      indexes_array.push(i);
    }
    setMealOptionsIndexes(indexes_array);
    return indexes_array;
  }
  const [Active, setActive] = useState(false);
  function SendEditedMeal(meal_base) {
    let new_meal_base = EditMealBase(meal_base);
    console.log(new_meal_base);
    setMealBase(new_meal_base);
    setSaveButtonText("Czekaj");
    setActive(true);
    axios
      .put(`${SERVER_URL}/${RestaurantCode}/meal_options`, new_meal_base, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setComunicateText("Pomyślnie zedytowano");
        }
        setSaveButtonText("Zapisz zmiany");
        setActive(false);
      })
      .catch((err) => {
        console.log(err.response);
        setComunicateText("Coś poszło nie tak...Spróbuj ponownie");
        setSaveButtonText("Zapisz zmiany");
        setActive(false);
      });
  }

  return (
    <div className="row px-0 mx-0">
      <div className="col-6 my-1">
        <div className="row   py-4">
          <div className="col-12 px-0 mb-2">
            <input
              class="form-control"
              value={MealName}
              onChange={(e) => {
                setMealName(e.target.value);
              }}
            />
          </div>
          <div className="col-12 px-0 mb-2">
            <input
              class="form-control"
              value={Ingreadients}
              onChange={(e) => {
                setIngreadients(e.target.value);
              }}
            />
          </div>
          <div className="col-12 px-0 mt-2">
            <FileBase64 multiple={false} onDone={File.bind()} />{" "}
          </div>
          <div className="col-12 px-0 mb-2">
            <div class="custom-control mt-3 custom-checkbox mr-sm-2">
              <input
                type="checkbox"
                class="custom-control-input"
                id="isPromotedCheckbox"
                checked={IsPromoted}
                onChange={(e) => setIsPromoted(e.target.checked)}
              />
              <label class="custom-control-label" for="isPromotedCheckbox">
                {" "}
                Wyróżnione{" "}
              </label>
            </div>{" "}
          </div>
          <button
            type="button"
            class="btn btn-success my-1 mr-2"
            disabled={Active}
            onClick={() =>
              SendEditedMeal(
                Menu.meal_categories[category_id].meal_bases[MealBaseIndex]
              )
            }
          >
            {SaveButtonText}
          </button>
          <button
            type="button"
            class="btn btn-success my-1"
            onClick={() => AddNewOption()}
          >
            {AddOptionText}
          </button>
          <p>{ComunicateText}</p>
        </div>{" "}
      </div>

      <div className="col-6 my-1 bg-white">
        {MealOptionIndexes.map((element) => (
          <EditSelectedMealOption
            category_id={category_id}
            meal_base_id={meal_base_id}
            meal_option_index={element}
            refresh_indexes={GetMealOptionsIndexes}
          /> // id={element} func={set} disabled={NameDisable}/>
        ))}
      </div>
    </div>
  );
}
