export const ALL_DATA = "ALL_DATA";
export const SET_COUPNS = "SET_COUPNS";
export const SET_ALERT = "SET_ALERT";
export const SET_MODE = "SET_MODE";
export const SET_IS_OPEN = "SET_IS_OPEN";
export const SET_ORDERS = "SET_ORDERS";
export const SET_ALLORDERS = "SET_ALLORDERS";
export const SET_RESTEURANTCODE = "SET_RESTEURANTCODE";
export const SET_LENGTHORDER = "SET_LENGTHORDER";
export const SET_SMSLEFT = "SET_SMSLEFT";
export const SET_PRICEPERSMS = "SET_PRICEPERSMS";
export const SET_SENDER = "SET_SENDER";
export const SET_SMSNOTYFICATION = "SET_SMSNOTYFICATION";
export const SET_QR = "SET_QR";
export const SET_RESTAURANT_STATUS = "SET_RESTAURANT_STATUS";
export const SET_ISSMSACTIVE = "SET_ISSMSACTIVE";
export const SET_TOGGLED = "SET_TOGGLED";
export const SET_SELLECTEDORDER = "SET_SELLECTEDORDER";
export const SET_SHOWMODAL = "SET_SHOWMODAL";
export const SET_ACCEPTS_PICKUP_IN_PERSON = "SET_ACCEPTS_PICKUP_IN_PERSON";
const initialState = {
  allData: {},
  coupons: {},
  url: "https://resmanager.feedweb.pl",
  server_URL: "http://s1.flexmenu.pl",
  qrurl: "https://flexmenu.feedweb.pl",
  restaurant_status: true,
  is_sms_active: null,
  qr: null,
  orders: {},
  resteurantCode: null,
  lengthOrder: 0,
  toggled:'',
  SMSLeft: null,
  pricePerSMS: null,
  sender: null,
  SMSnotyfication: null,
  sellecTedOrder:null,
  backgroundImage: "https://globalfiles.flexmenu.pl/img/flex_admin_bg.png",
  showModal:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOGGLED": {
      return {
        ...state,
        toggled: action.payload,
      };
    }
    case "SET_SHOWMODAL": {
      return {
        ...state,
        showModal: action.payload,
      };
    }
    case "SET_SELLECTEDORDER": {
      return {
        ...state,
        sellecTedOrder: action.payload,
      };
    }
    case "SET_SMSNOTYFICATION": {
      return {
        ...state,
        SMSnotyfication: action.payload,
      };
    }
    case "SET_SMSLEFT": {
      return {
        ...state,
        SMSLeft: action.payload,
      };
    }
    case "SET_PRICEPERSMS": {
      return {
        ...state,
        pricePerSMS: action.payload,
      };
    }
    case "SET_SENDER": {
      return {
        ...state,
        sender: action.payload,
      };
    }
    case "ALL_DATA": {
      return {
        ...state,
        allData: action.payload,
      };
    }
    case "SET_COUPNS": {
      return {
        ...state,
        coupons: action.payload,
      };
    }
    case "SET_MODE": {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case "SET_IS_OPEN": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    case "SET_ALLORDERS": {
      return {
        ...state,
        orders: action.payload,
      };
    }
    case "SET_RESTAURANT_STATUS": {
      return {
        ...state,
        restaurant_status: action.payload,
      };
    }
    case "SET_ORDERS": {
      let order = state.orders;
      let NewMeals = [];
      action.payload.meal_options.forEach((element) => {
        NewMeals.push({
          name: element.name,
          additions: element.additions,
        });
      });
      order[action.payload.id] = {
        id: action.payload.id,
        to_pay: action.payload.to_pay,
        ordered_date: action.payload.ordered_date,
        ordered_time: action.payload.ordered_time,
        payment_status: action.payload.payment_status,
        payment_type: action.payload.payment_type,
        phone_number: action.payload.phone_number,
        preparation_time: action.payload.preparation_time,
        progress: action.payload.progress,
        restaurants_order_id: action.payload.restaurants_order_id,
        client_name: action.payload.client_name,
        delivery_address: action.payload.delivery_address,
        delivery_city: action.payload.delivery_city,
        comment: action.payload.comment,
        email: action.payload.email,
        meals: NewMeals,
      };
      // console.log(action.payload);
      return {
        ...state,
        orders: order,
      };
    }
    case "SET_RESTEURANTCODE": {
      return {
        ...state,
        resteurantCode: action.payload,
      };
    }
    case "SET_LENGTHORDER": {
      return {
        ...state,
        lengthOrder: action.payload,
      };
    }
    case "SET_QR": {
      return {
        ...state,
        qr: action.payload,
      };
    }
    case "SET_ISSMSACTIVE": {
      return {
        ...state,
        is_sms_active: action.payload,
      };
    }
    case "SET_ACCEPTS_PICKUP_IN_PERSON": {
      return {
        ...state,
        accepts_pickup_in_person: action.payload,
      };
    }
    default: {
      //   console.log(`Unknown action ${action.type}`);
      return {
        ...state,
      };
    }
  }
};

export const SetshowModal = (showModal, dispatch) => {
  if (dispatch({ type: SET_SHOWMODAL, payload: showModal })) {
    return true;
  }
};
export const SetsellecTedOrder = (sellecTedOrder, dispatch) => {
  if (dispatch({ type: SET_SELLECTEDORDER, payload: sellecTedOrder })) {
    return true;
  }
};
export const SetToggled = (toggled, dispatch) => {
  if (dispatch({ type: SET_TOGGLED, payload: toggled })) {
    return true;
  }
};
export const SetIsSMSActive = (is_sms_active, dispatch) => {
  if (dispatch({ type: SET_ISSMSACTIVE, payload: is_sms_active })) {
    return true;
  }
};
export const AllData = (allData, dispatch) => {
  if (dispatch({ type: ALL_DATA, payload: allData })) {
    return true;
  }
};
export const SetCoupons = (coupons, dispatch) => {
  if (dispatch({ type: SET_COUPNS, payload: coupons })) {
    return true;
  }
};
export const SetMode = (mode, dispatch) => {
  if (dispatch({ type: SET_MODE, payload: mode })) {
    return true;
  }
};
export const SetIsOpen = (isOpen, dispatch) => {
  if (dispatch({ type: SET_IS_OPEN, payload: isOpen })) {
    return true;
  }
};
export const SetOrders = (orders, dispatch) => {
  if (dispatch({ type: SET_ORDERS, payload: orders })) {
    return true;
  }
};
export const SetAllOrders = (orders, dispatch) => {
  if (dispatch({ type: SET_ALLORDERS, payload: orders })) {
    return true;
  }
};
export const SetResteurantCode = (resteurantCode, dispatch) => {
  if (dispatch({ type: SET_RESTEURANTCODE, payload: resteurantCode })) {
    return true;
  }
};
export const SetLengthOrder = (lengthOrder, dispatch) => {
  if (dispatch({ type: SET_LENGTHORDER, payload: lengthOrder })) {
    return true;
  }
};
export const SetSMSLeft = (SMSLeft, dispatch) => {
  if (dispatch({ type: SET_SMSLEFT, payload: SMSLeft })) {
    return true;
  }
};
export const SetpricePerSMS = (pricePerSMS, dispatch) => {
  if (dispatch({ type: SET_PRICEPERSMS, payload: pricePerSMS })) {
    return true;
  }
};
export const Setsender = (sender, dispatch) => {
  if (dispatch({ type: SET_SENDER, payload: sender })) {
    return true;
  }
};
export const SetSMSnotyfication = (SMSnotyfication, dispatch) => {
  if (dispatch({ type: SET_SMSNOTYFICATION, payload: SMSnotyfication })) {
    return true;
  }
};
export const SetQR = (qr, dispatch) => {
  if (dispatch({ type: SET_QR, payload: qr })) {
    return true;
  }
};
export const SetRestaurantStatus = (restaurant_status, dispatch) => {
  if (dispatch({ type: SET_RESTAURANT_STATUS, payload: restaurant_status })) {
    return true;
  }
};
export const SetAcceptsPickupInPerson = (accepts_pickup_in_person, dispatch) => {
  if (dispatch({ type: SET_ACCEPTS_PICKUP_IN_PERSON, payload: accepts_pickup_in_person })) {
    return true;
  }
};
