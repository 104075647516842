import React from "react";
import { Route } from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { withRouter,NavLink,useLocation } from 'react-router-dom'



import { useHistory } from "react-router-dom";


const SettingsNavNew = (props) =>
{
  let history = useHistory();


  const redirectTexts = e => {
		e.preventDefault()
		history.push('/customize/texts')
	}
	const redirectStyle = e => {
		e.preventDefault()
		history.push('/customize/style')
	}
	const redirectAlerts = e => {
		e.preventDefault()
		history.push('/customize/alerts')
	}
	const redirectCompanyInfo = e => {
		e.preventDefault()
		history.push('/customize/companyinfo')
	}
	const redirectIcons = e => {
		e.preventDefault()
		history.push('/customize/icons')
	}
	const redirectDelivery = e => {
		e.preventDefault()
		history.push('/customize/delivery')
    
	}


  const reddirectTo = (e,path) => {
    e.preventDefault()
		history.push(path)

  }
  const location = useLocation();

 

   
 

    return <>
 

 
    <Disclosure as="nav" className="bg-white   ">
       
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 ">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                 
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                
                <div className="hidden sm:ml-6 sm:flex ">
                 
                          {
                            props.tabs.map(({name,path} )=>   <a

                            
                            className={`${location.pathname==path ? "border-indigo border-b-4   text-indigo" :"border-gray border-b-2 text-gray-500 " } no-underline inline-flex items-center px-5 font-medium hover:no-underline`}
                            href="#"
                            key={name}   
                          
                              
                              onClick={e => {
                              reddirectTo(e,path)
                            }}
                          >
                            {name}
                          </a>)
                        }
                        

                </div>
              </div>
              
            </div>
          </div>

          
        </>
      
    </Disclosure>
 
    
    
    </>
}
export default withRouter(SettingsNavNew);