import React, { useState, useEffect } from 'react'
import BreadcrumbNav from '../BreadcrumbsNav/BreadcrumbsNav'
import ToogleSwitch from '../ToggleSwitch/ToggleSwitch'
import { useSelector } from "react-redux";
import axios from "axios";
import NewDeliveryZoneFormKM from '../Km/NewDeliveryZoneFormKM/NewDeliveryZoneFormKM'

const City = ({setChoosenDeliveryType}) => {
	const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
	const CODE = useSelector((e) => e.UserReducer.resteurantCode)
	const accepts_pickup_in_person = useSelector((e) => e.UserReducer.accepts_pickup_in_person);
	const [activeForm, setActiveForm] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [pickDeliveryZoneCal, setPickDeliveryZoneCal] = useState('')
	const [freeDelivery, setFreeDelivery] = useState(false)
	const [showGoogleMaps, setShowGoogleMaps] = useState(false)
	const [googleVisible, setGoogleVisible] = useState(false);
	const [firstLoadDone, setFirstLoadDone] = useState(false);

	useEffect(() => {
		setActiveForm(accepts_pickup_in_person);
		setFirstLoadDone(true);
	}, [])

	const getFreeDelivery = val => {
		setFreeDelivery(val)
	}

	const getType = value => {
		console.log(value, 'jestem w rodziuc')
		setPickDeliveryZoneCal(value)
	}

	const showGoogleMapsSection = () => {
		setShowGoogleMaps(!showGoogleMaps);
		setGoogleVisible(true);
	}

	const onChangeFreePickup = () => {
		setActiveForm(!activeForm);
	}

	const onChangeFreeDelivery = () => {
		setFreeDelivery(!freeDelivery);
	}

	useEffect(() => {
		async function fetchData() {
			let json = {
				accepts_pickup_in_person: activeForm
			}
			await axios
			.patch(`${SERVER_URL}/${CODE}/restaurant/`, 
			json,
			{
			  "Content-Type": "application/json",
			})
			.then((response) => {
			  setActiveForm(response.data.accepts_pickup_in_person);
			  })
	  
			.catch((err) => {
			  //console.log(err.response);
			});
		  }
		  if (firstLoadDone === true) {
			fetchData();
		  }
	}, [activeForm])



	return (
		<>
			{/* //Dostawa km */}
			<div className='pl-5 pt-4 pr-5 mx-auto'>
				<div>
					<BreadcrumbNav />

					<div className='flex justify-between mt-2'>
						<p className='text-darkgray font-bold text-xl pt-3'>
							Cenniki dostaw
						</p>
						<ToogleSwitch
							name='Odbiór osobisty'
							enabled={activeForm}
							onChange={onChangeFreePickup}
						/>

					</div>
					<div className='mt-2 py-3 border-t border-lightgray'>
						<p className='text-darkgray font-bold text-lg'>
							{' '}
							Nowa opcja dostawa{' '}
						</p>
						<ToogleSwitch
							name='Darmowa Dostawa'
							description='Rabat na koszt dostawy od danej kwoty zamówienia, np. jeśli wartość zamówienia wyniesie 100zł lub więcej dostawa na tym terenie będzie darmowa.'
							enabled={freeDelivery}
							onChange={onChangeFreeDelivery}
						/>
					</div>

					<NewDeliveryZoneFormKM
						active={activeForm ? false : true}
						setShowModal={setShowModal}
						activeDelivery={freeDelivery ? false : true}
						showGoogleMaps={showGoogleMaps}
						googleVisible={googleVisible}
						setChoosenDeliveryType={setChoosenDeliveryType}
						showGoogleMapsSection={showGoogleMapsSection}
					/>
				</div>
			</div>
		</>
	)
}

export default City
