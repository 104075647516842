import React, { useState, useEffect } from "react";
import FileBase64 from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import ToogleSwitch from "../ToggleSwitch/ToggleSwitch";
import axios from "axios";
import EditNewMealOptionForm from "../EditNewMealOptionForm/EditNewMealOptionForm";
import { SetMenuCategory, SetMenuToEdit } from "../../../store/MenuReducer";

export default function AddMealForm(props) {

  const dispatch = useDispatch();
  const Code = useSelector((e) => e.UserReducer.resteurantCode);

  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const [MealName, setMealName] = useState("");
  const [Description, setDescription] = useState("");
  const [IsPromted, setIsPromoted] = useState(false);
  const [IsHighlighted, setIsHighlighted] = useState(false);
  const [MealBaseIndex, setMealBaseIndex] = useState(0);
  const [MealOptionIndexes, setMealOptionsIndexes] = useState([]);
  const [SizeAdjustable, setSizeAdjustable] = useState(false);
  const [Text, setText] = useState("");
  const [FileName, setFileName] = useState("");
  const [FileText, setFileText] = useState("");
  const [Active, setActive] = useState(true);
  const [ComunicateText, setComunicateText] = useState("");
  const [OptionsList, setOptionsList] = useState({
    1: { id: 1, name: null, price: 0, kcal: 0, size: 0 },
  });

  const [NameDisable, setNameDisable] = useState(true);
  const [Check, setCheck] = useState(0);
  useEffect(() => 
  {
    const timer = setTimeout(()=>setComunicateText(""),1500)
    return () => clearTimeout(timer)
  }
  ,[ComunicateText])
  const echo = (e) => {
    if (e.target.checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };
  const checkHowManyOptions = (value) => {
    if (Object.keys(value).length === 1) {
      setNameDisable(true);
    } else {
      setNameDisable(false);
    }
  };

  const getMenuToEdit = async () => {
    await axios
      .get(`${SERVER_URL}/${Code}/edit_menu`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        // //console.log(response.data);
        if (SetMenuToEdit(response.data, dispatch)) {
          // //console.log("ooocholera");
        }
      })

      .catch((err) => {
        //console.log(err.response);
        
      });
  };


  const getAllMenu = async () => {
    await axios
      .get(`${URL}/get_menu.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //  //console.log(response.data);
        
      })

      .catch((err) => {
        //console.log(err.response);

      });
  };
  
  const DescriptionValid = (value) => {
    setDescription(value);
  };

  console.log(Menu);

  const checkOptionsList = (value) => {
    let flag = true;
    // //console.log(Object.keys(value).length);
     

    console.log(" lista opcji all");
    console.log(value );

    if (Object.keys(value).length > 1) {
      Object.keys(value).map((element) => {
       
        
     


        //
        if (
          value[element].name !== null &&
          value[element].price !== null &&
         !Number.isNaN(parseFloat(value[element].price)) &&
          value[element].name !== ""
        ) {
      console.log("mamy to - lista opcji");
        } else {
          flag = false;
         console.log("mnie mamy tego - lista opcji");
        }
      });
    } else if (Object.keys(value).length === 1) {
      Object.keys(value).map((element) => {
        if (
          value[element].price !== null &&
          !Number.isNaN(parseFloat(value[element].price))
        ) {
          // //console.log("mamy to");
        } else {
          flag = false;
          // //console.log("mnie mamy tego");
        }
      });
    }

    return flag;
  };


  const [IconsArray, setIconsArray] = useState({});
  const [Length, setLength] = useState(1);
  const [Icon1, setIcon1] = useState(false);
  const [Icon2, setIcon2] = useState(false);
  const [Icon3, setIcon3] = useState(false);
  const [Icon4, setIcon4] = useState(false);
  const [Icon5, setIcon5] = useState(false);
  const [Icon6, setIcon6] = useState(false);

  const clear = () => {
    setOptionsList({});
    setDescription("");
    setMealName("");

    let d = new Date();
    let n = d.getTime();
    setOptionsList({
      n: { id: n, name: null, price: 0, kcal: 0, size: 0 },
    });
    setNameDisable(true);
  };
  const addMeal = () => {
  
       console.log(props.categoryId)
       console.log(MealName)
       console.log(Description)
       console.log(OptionsList)
        
    if (
      props.categoryId !== null &&
      MealName !== "" &&
      Description !== "" &&
      checkOptionsList(OptionsList)
    ) {
      setActive(false);
      setText("Czekaj");
      console.log("rozpoczynam wysyłanie....... ")
      let json = {
        meal_category: props.categoryId,
        name: MealName,
        ingredients: Description,
        photo_url: FileName,
        photo: FileText,
        is_promoted: Check,
        options: OptionsList,
        icons: IconsArray,
        
      };
       
      console.log("zaraz przed podłączeniem do bazy");
      axios
      .post(`${SERVER_URL}/${ResteurantCode}/meal_options`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        ////console.log(response);

        console.log("jesteśmy w odp/ response");
        console.log(response);
        if (response.status === 201 && response.statusText === "Created") {
          //  //console.log(response);

          getAllMenu();
          getMenuToEdit()

          console.log("jesteśmy w odp jest OK status 201");
          clear();
          setActive(true);
          setText("Dodaj posiłek");
          setComunicateText("Dodano! Możesz teraz dodać kolejny posiłek");
          
        }
      })
      .catch((err) => {
        //console.log(err.response);
        console.log("wkradł się błąd");
        console.log(err);
        setComunicateText("Coś poszło nie tak...Spróbuj ponownie ");


      });

      getAllMenu();
      getMenuToEdit()
  } else {
    setComunicateText("Uzupełnij wszytkie wymagane pola");
  }
};


  

  const onChangePromotedButton = () => {
    setIsPromoted(!IsPromted);
  };
  const onChangeHighlightedButton = () => {
    setIsHighlighted((IsHighlighted) => !IsHighlighted);
  };
  const onChangeSizeAdjustableButton = () => {
    setSizeAdjustable((SizeAdjustable) => !SizeAdjustable);
  };
  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name);
  };
 

  const removeOpt = (id) => {
    let opt = OptionsList;
    setOptionsList({});
    let optLength = Object.keys(opt).length;
    console.log(optLength);
    if (optLength > 1) {
      delete opt[id];

      setOptionsList(opt);
    }
    console.log(opt);
    setOptionsList(opt);
    checkHowManyOptions(opt);
    setLength(Object.keys(opt).length);
    //  //console.log(opt);
  };

  const set = (id, value) => {
    console.log("lista opcji/wariantów");
    console.log(OptionsList);
    let opt = OptionsList;
    setOptionsList({});
    opt[id] = value;
    setOptionsList(opt);
    setLength(Object.keys(opt).length);
    //  //console.log(opt);
  };
  const addOpt = () => {
     
    console.log(OptionsList)
    
    let opt = OptionsList;
    setOptionsList({});
    let d = new Date();
    let n = d.getTime();
    opt[n] = { id: n, name: null, price: null, kcal: null, size: null };
    setOptionsList(opt);
    setLength(Object.keys(opt).length);
    console.log(opt);
    checkHowManyOptions(opt);
////
    let option = {
      id: null,
      meal_base: props.dishId,
      name: "",
      size: "",
      kcal: "",
      price: null,
      is_active: true,
    };
    Menu.meal_categories[props.categoryId].meal_bases[
      MealBaseIndex
    ].meal_options.push(option);
    // console.log(Menu.meal_categories[category_id].meal_bases[MealBaseIndex].meal_options)
    GetMealOptionsIndexes(MealBaseIndex);


  };

  function GetMealOptionsIndexes(meal_base_index) {
     //console.log(meal_base_index);
    let indexes_array = [];
    for (
      let i = 0;
      i <
      Menu.meal_categories[props.categoryId].meal_bases[meal_base_index]
        .meal_options.length;
      i++
    ) {
      indexes_array.push(i);
    }
    setMealOptionsIndexes(indexes_array);
    return indexes_array;
  }
  return (
    <div>
      <div className=" w-912px border-b border-lightgray mx-3 my-0">
        <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i pl-3 ">
          {props.category}  
        </p>
      </div>

      {props.category && (
        <div>
          <div className="flex border-lightgray mt-2 border-b">
            <div className="w-50 border-r border-lightgray  ">
              <div className="text-darkgray font-bold text-base pt-3 font-weight: 300 font-i pl-1 mx-3 my-0 mb-3">
                Informacje ogólne
              </div>
              <label className="mb-0 mx-3 text-gray-500">Nazwa dania</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full     px-1 sm:text-sm  border-borderGray rounded-md"
                  placeholder=""
                  value={MealName}
                  onChange={(e) => {
                    setMealName(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>

              <label className="mb-0 mx-3 text-gray-500">Opis</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <textarea
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-1 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={Description}
                  onChange={(e) => {
                    DescriptionValid(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
              <label className="mb-0 mx-3 text-gray-500">Oznaczenia</label>
              <div class="mb-3 relative rounded-md mx-3 ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm  border-borderGray rounded-md"
                  placeholder=""
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>

              <div class="input-group mb-10 mt-2   opacity-0 z-10 ">
                <FileBase64
                  multiple={false}
                  onDone={File.bind()}
                  className=""
                />
              </div>
              <div className="flex items-start relative -top-16 left-0 mx-3">
                <button className="mr-2 border-gray border-2 px-2 rounded-md">
                  {" "}
                  Dodaj logo
                </button>
                <p className="text-gray-500">JPG lub PNG max 2mb</p>
              </div>
              <div className="mx-3 ">
                <div className=" mb-4 ">
                  <ToogleSwitch
                    name="Wyróżnij"
                    description="Wróżmnienie pozycji kolorem przewodnim menu"
                    enabled={IsHighlighted}
                    onChange={onChangeHighlightedButton}
                  />
                </div>

                <div className="mb-4">
                  <ToogleSwitch
                    name="Promuj"
                    description='Pozycja trafi na samą górę menu do sekcji "promowane"'
                    enabled={IsPromted}
                    onChange={onChangePromotedButton}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-darkgray font-bold text-base mb-0 ">
                      Konieczny wybór składników
                    </p>
                    <p className="text-sm text-gray-500 mt-0">
                      Klient decyduje o składnikach np. rodzaj mięsa
                    </p>
                  </div>
                  <div className=" bg-lightgray rounded-full h-4 w-53px">
                    <p className="text-darkgray   text-xs align-middle text-center ">
                      Wkrótce
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-50 ml-3">
              <div className="text-darkgray font-bold text-base pt-3 font-weight: 300 font-i pl-1 mb-3">
                Warianty cenowe
              </div>
              <ToogleSwitch
                name="Rozmiary wariantów"
                description="Pozwala określić rozmiary i/lub kalorie dania"
                enabled={SizeAdjustable}
                onChange={onChangeSizeAdjustableButton}
              />

              <div className="flex justify-between items-center mt-3">
                <div>
                  <p className="text-darkgray font-bold text-base mb-0 ">
                    Płatne opakowania
                  </p>
                  <p className="text-sm text-gray-500 mt-0">
                    Dodatkowo płatne opakowania na wynos
                  </p>
                </div>
                <div className=" bg-lightgray rounded-full h-4 w-53px">
                  <p className="text-darkgray   text-xs align-middle text-center ">
                    Wkrótce
                  </p>
                </div>
              </div>

              {Object.keys(OptionsList).map((element) => (
                    <EditNewMealOptionForm
                      id={OptionsList[element].id}
                      func={set}
                      func2={removeOpt}
                      disabled={NameDisable}
                      sizeAdjustable={SizeAdjustable}
                    />
                  ))}

            

              <div className="mt-3">
                <button className="text-sm font-medium" onClick={addOpt}>
                  + Dodaj wariant
                </button>
              </div>
            </div>
          </div>

          <div className=" w-976px flex justify-between bg-backgroundGray h-100">
            <div className="flex mt-3">
               
            </div>
            <div className="mt-3">
              {ComunicateText}
              <button 
              className=" mr-5 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={addMeal}
              >
                Zapisz
              </button>
              
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
