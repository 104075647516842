import React from "react";
import List from "./List";
import { useSelector, useDispatch } from "react-redux";
import HeaderNew from "../Components/HeaderNew";
import NavNew from "./NavNew";
export default function EditMenu() {
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <NavNew />
          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <>
                <div className="row">
                  <div className="col-12 my-5 text-center">
                    <h1 className="upper Bold">Edytujesz menu</h1>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 my-3 ">
                    <List />
                  </div>
                  <div className="col-md-8 my-3 "></div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
