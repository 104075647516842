import React, { useEffect, useState } from "react";

import axios from "axios";
import NavNew from "./NavNew";
import { useSelector } from "react-redux";
import HeaderNew from "./HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
const Alerts = () => {
  const URL = useSelector((e) => e.UserReducer.url);

  const [HeaderText1, setHeaderText1] = useState("");
  const [HeaderText2, setHeaderText2] = useState("");
  const [ShowMoreOptionsButtonText, setShowMoreOptionsButtonText] =
    useState("");

  const [HeaderConfirmSectionText, setHeaderConfirmSectionText] = useState("");
  const [AlertCloseText, setAlertCloseText] = useState("");

  const [MyOrderHeaderText, setMyOrderHeaderText] = useState("");
  const [CodeInpotPleaceholder, setCodeInpotPleaceholder] = useState("");
  const [ClientTextPleaceholder, setClientTextPleaceholder] = useState("");
  const [DeliveryAdresHeaderText, setDeliveryAdresHeaderText] = useState("");
  const [ContactHeaderText, setContactHeaderText] = useState("");
  const [SubmitButtonText, setSubmitButtonText] = useState("");

  const [OpenHoursInformationText, setOpenHoursInformationText] = useState("");
  const [SocialMediaHeaderText, setSocialMediaHeaderText] = useState("");
  const [AcceptOrderHeaderText, setAcceptOrderHeaderText] = useState("");
  const [AcceptOrderComunicatText, setAcceptOrderComunicatText] = useState("");
  const [DenyOrderHeaderText, setDenyOrderHeaderText] = useState("");
  const [DenyOrdedComunicatText, setDenyOrdedComunicatText] = useState("");
  const [InformationTitle, setInformationTitle] = useState("");
  const [InformationText, setInformationText] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  const [SMSText, setSMSText] = useState("");
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setHeaderText1(UserInfo.editable_texts.header_text_1);
      setHeaderText2(UserInfo.editable_texts.header_text_2);
      setShowMoreOptionsButtonText(UserInfo.editable_texts.meal_sizes_button);
      //setCartButtonTExt()
      //setCartSectionText();
      setHeaderConfirmSectionText(UserInfo.editable_texts.order_summary);
      setAlertCloseText(UserInfo.editable_texts.restaurant_auto_closed_alert);
      setMyOrderHeaderText(UserInfo.editable_texts.my_order_label);
      setDeliveryAdresHeaderText(
        UserInfo.editable_texts.delivery_address_header
      );
      setCodeInpotPleaceholder(UserInfo.editable_texts.promo_code_label);
      setClientTextPleaceholder(UserInfo.editable_texts.comment_to_order);
      setContactHeaderText(UserInfo.editable_texts.delivery_client_contact);
      setSubmitButtonText(UserInfo.editable_texts.confirm_order_button);
      setOpenHoursInformationText(
        UserInfo.editable_texts.restaurant_open_hours
      );
      setSocialMediaHeaderText(UserInfo.editable_texts.visit_socials);
      setAcceptOrderHeaderText(UserInfo.editable_texts.order_accepted_title);
      setAcceptOrderComunicatText(
        UserInfo.editable_texts.online_order_accepted_text
      );
      setDenyOrderHeaderText(UserInfo.editable_texts.order_error_title);
      setDenyOrdedComunicatText(
        UserInfo.editable_texts.online_order_error_text
      );
      setSMSText(UserInfo.editable_texts.sms_order_notification);
      setInformationTitle(UserInfo.editable_texts.information_title);
      setInformationText(UserInfo.editable_texts.information_text);
    }
  }, [UserInfo]);

  const setWebsiteData = () => {
    let json = {
      editable_texts: {
        header_text_1: HeaderText1,
        header_text_2: HeaderText2,
        meal_sizes_button: ShowMoreOptionsButtonText,
        sms_order_notification: SMSText,
        order_summary: HeaderConfirmSectionText,
        my_order_label: MyOrderHeaderText,
        promo_code_label: CodeInpotPleaceholder,
        comment_to_order: ClientTextPleaceholder,
        delivery_address_header: DeliveryAdresHeaderText,
        delivery_client_contact: ContactHeaderText,
        confirm_order_button: SubmitButtonText,
        restaurant_open_hours: OpenHoursInformationText,
        visit_socials: SocialMediaHeaderText,
        order_accepted_title: AcceptOrderHeaderText,
        online_order_accepted_text: AcceptOrderComunicatText,
        order_error_title: DenyOrderHeaderText,
        online_order_error_text: DenyOrdedComunicatText,
        restaurant_auto_closed_alert: AlertCloseText,
        information_title: InformationTitle,
        information_text: InformationText,
      },
    };
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column bg-white">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Strefy dostaw", path: "/customize/delivery" },
              { name: "Dane firmy", path: "/customize/companyinfo" },
              { name: "Alerty", path: "/customize/alerts" },
              { name: "Wygląd", path: "/customize/style" },
              { name: "Dodatkowe teksty", path: "/customize/texts" },
              { name: "Legenda", path: "/customize/icons" },
            ]}
          />
          <NavNew />

          <div className="max-w-7xl mx-auto ">
            <div className="pl-5 pt-1 pr-5  ">
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Ogólne
                </p>
              </div>
              <label className="mb-0">
                Alert gdy restauracja jest zamknięta
              </label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={AlertCloseText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setAlertCloseText(e.target.value);
                  }}
                />
              </div>

              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Status płatności
                </p>
              </div>
              <label className="mb-0">
                Zamówienie zaakceptowane - Nagłówek
              </label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={DenyOrderHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setDenyOrderHeaderText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0"> Zamówienie zaakceptowane - Opis</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <textarea
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={AcceptOrderComunicatText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setAcceptOrderComunicatText(e.target.value);
                  }}
                />
              </div>

              <label className="mb-0">Zamówienie odrzucone- Nagłówek</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={DenyOrderHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setDenyOrderHeaderText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Zamówienie odrzucone-Opais</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <textarea
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={DenyOrdedComunicatText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setDenyOrdedComunicatText(e.target.value);
                  }}
                />
              </div>

              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Zmiana statusu zamówienia
                </p>
              </div>
              <label className="mb-0">
                Powiadomienie SMS przy zmianie statusu zamówienia na "w dostawie
              </label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <textarea
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={SMSText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setSMSText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="  fixed bottom-1 right-2  w-1360 mb-4 ">
              <button
                type="button "
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setWebsiteData()}
                disabled={!Active}
              >
                {Text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Alerts;
