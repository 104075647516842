import React, { useState, useEffect } from "react";

export default function Cupon({ func, element }) {
  const [ArrayMine, setArrayMine] = useState([]);
  useEffect(() => {
    let arraymine = [];
    element.coupons.map((e) => {
      arraymine.push(e.id);
    });
    setArrayMine(arraymine);
    //   console.log(arraymine);
  }, []);
  // console.log(element);

  return (
    <>
      <div className="flex justify-between border-bottom justify-items-start mx-0 px-0 my-2">
        <div className="flex px-0 my-2">
          <div className="text-base w-36 mr-3  font-weight:400 d-flex  ">
            {element.code}
          </div>
          <div className="text-sm w-28  px-3 font-weight:300 d-flex  ">
            {element.percent}
            {"%"}
          </div>
          <div className="text-base w-42 pr-2    font-weight:400   ">
            {element.coupons.map((e) => (
              <p className="mb-1 w-42">
                <span>{e.meal_base}</span>
                {e.meal_option !== null ? (
                  <span> {"- " + e.meal_option}</span>
                ) : (
                  ""
                )}
              </p>
            ))}
          </div>
        </div>

        <div className="h-10">
          <button
            type="button"
            class="h-10 w-24 mb-2 px-2 border-bottom text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => func(ArrayMine)}
          >
            Usuń
          </button>
        </div>
      </div>
    </>
  );
}
