import React from 'react';
import { Pie } from 'react-chartjs-2';

function VerticalBarChart(params) {
    return (
        <Pie
            datasetIdKey='id'
            data={{
                labels: params.labels,
                datasets: params.datasets
            }}
            options={{
                plugins: {
                  title: {
                    display: true,
                    text: params.name
                  },
                },
                responsive: true,
              }}
        />

    )
}

export default VerticalBarChart;