import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { Accordion, Card } from "react-bootstrap";

import NavNew from "./NavNew";
import HeaderNew from "../Components/HeaderNew";
import axios from "axios";
const Delivery = () => {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const URL = useSelector((e) => e.UserReducer.url);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const QRURL = useSelector((e) => e.UserReducer.qrurl);
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Dodaj kod");
  const [ComunicateText, setComunicateText] = useState("");
  const [Delivery, setDelivery] = useState(null);

  useEffect(() => {
    getDeliveryPlaces();
  }, []);
  useEffect(() => {
    getDeliveryPlaces();
  }, [Active]);
  const getDeliveryPlaces = async () => {
    await axios
      .get(`${URL}/get_delivery_places.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        setDelivery(response.data.places);
      })

      .catch((err) => {
        //console.log(err.response);
      });
  };
  const [City, setCity] = useState("");
  const [FreeAbove, setFreeAbove] = useState("");
  const [Price, setPrice] = useState("");
  const ValidCity = (Value) => {
    setCity(Value);
    setComunicateText("");
  };
  const ValidPrice = (Value) => {
    setPrice(Value);
    // //console.log(Value);
  };
  const ValidFreeAbove = (Value) => {
    setFreeAbove(Value);
    ////console.log(Value);
  };
  const removeCity = (id) => {
    ////console.log(id);
    axios
      .delete(`${SERVER_URL}/${ResteurantCode}/delivery_place/${id}`)
      .then((response) => {
        ////console.log(response);
        if (response.status === 204 && response.statusText === "No Content") {
          console.log(response);
          getDeliveryPlaces();
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
    setComunicateText("");
  };
  const addDelivery = () => {
    if (City !== "") {
      setActive(false);
      setText("Czekaj");
      let json = {
        name: City,
        delivery_price: 0,
        free_above: 0,
        is_place: false,
      };
      ////console.log(json);
      axios
        .post(`${SERVER_URL}/${ResteurantCode}/delivery_places`, json, {
          "Content-Type": "application/json",
        })
        .then((response) => {
          // //console.log(response);
          if (response.status === 201 && response.statusText === "Created") {
            //  //console.log(response);

            setActive(true);
            setText("Dodaj strefę");
            setComunicateText("Dodano! Możesz teraz dodać kolejną strefę");
            getDeliveryPlaces();
            setCity("");
          }
        })
        .catch((err) => {
          //console.log(err.response);
        });
    } else {
      setComunicateText("Uzupełnij wszytkie pola");
    }
  };
  const downloadQRCode = (id) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("QR_code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `Strefa_${id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <NavNew />
          <div className="container-fluid">
            <div
              className="container-fluid display  px-0 position-relative "
              style={{ background: "white", minHeight: window.innerHeight }}
            >
              <div className="max-w-7xl mx-auto ">
                <div className="pl-5 pt-1 pr-5  ">
                  <div className=" mt-2">
                    <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                      Bezkontaktowe menu
                    </p>
                    <p className="text-darkgray font-weight: 600 font-i">
                      Przemyśl odpowiednie nazewnictwo Twoich kodów QR, ponieważ
                      później będą one wyświetlane na zamówieniu, po to aby
                      wiedzieć gdzie je dostarczyć.
                    </p>
                  </div>
                  <label>Stwórz kod QR</label>
                  <div class="mb-3 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      value={City}
                      id="basic-url"
                      onChange={(e) => {
                        ValidCity(e.target.value);
                      }}
                      aria-describedby="basic-addon3"
                      placeholder="Nazwa kodu"
                    />
                  </div>

                  <div className="  relative  ">
                    <div className="  absolute flex flex-col  mb-14 bottom-100 right-0    items-end">
                      <button
                        type="button"
                        className=" content-end px-3  py-2 border w-36 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => addDelivery()}
                        disabled={!Active}
                      >
                        {Text}
                      </button>
                      <p className=" ">{ComunicateText}</p>
                    </div>
                  </div>

                  <div className=" my-10">
                    <p className="text-darkgray  mt-12 font-bold text-xl pt-3 font-weight:600 font-i">
                      Twoje kody
                    </p>
                  </div>

                  {Delivery !== null
                    ? Object.keys(Delivery).map((element) => {
                        if (!Delivery[element].is_place) {
                          return (
                            <>
                              <div className="w-full ">
                                <div className="flex border-bottom justify-between justify-items-start mb-4 pb-2">
                                  <div className=" w-28">
                                    {Delivery[element].name}
                                  </div>
                                  <QRCode
                                    value={
                                      QRURL + "/qr/1/" + Delivery[element].id
                                    }
                                    id="QR_code"
                                  />

                                  <div className=" flex">
                                    <button
                                      type="button"
                                      className=" mx-2 h-8 items-center px-1 mb-1   border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-darkIndigo bg-lightIndigo hover:bg-indigo hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={() =>
                                        downloadQRCode(Delivery[element].id)
                                      }
                                    >
                                      Pobierz
                                    </button>
                                    <button
                                      type="button"
                                      className=" h-8 items-center px-3 w-24    text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      onClick={() =>
                                        removeCity(Delivery[element].id)
                                      }
                                    >
                                      Usuń
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Delivery;
