import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SetToggled } from '../store/UserReducer'
import { withRouter } from 'react-router-dom'

export function HeaderNew({ history }) {
	const Toggled = 'toggled'
	const dispatch = useDispatch()

	const redirectOrders = e => {
		e.preventDefault()
		history.push('/orders')
	}
	const redirectQR = e => {
		e.preventDefault()
		history.push('/qr')
	}
	const redirectAddMeal = e => {
		e.preventDefault()
		history.push('/menu/addmeal')
	}
	const redirectAddCategory = e => {
		e.preventDefault()
		history.push('/menu/addcategory')
	}

	const redirectMenu = e => {
		e.preventDefault()
		history.push('/menu')
	}
	const redirectEditMenu = e => {
		e.preventDefault()
		history.push('/menu/editmenu')
	}
	const redirectAddAdditions = e => {
		e.preventDefault()
		history.push('/menu/addadditions')
	}
	const redirectTexts = e => {
		e.preventDefault()
		history.push('/customize/texts')
	}
	const redirectStyle = e => {
		e.preventDefault()
		history.push('/customize/style')
	}
	const redirectAlerts = e => {
		e.preventDefault()
		history.push('/customize/alerts')
	}
	const redirectCompanyInfo = e => {
		e.preventDefault()
		history.push('/customize/companyinfo')
	}
	const redirectIcons = e => {
		e.preventDefault()
		history.push('/customize/icons')
	}
	const redirectDelivery = e => {
		e.preventDefault()
		history.push('/customize/delivery')
	}
	const redirectCupons = e => {
		e.preventDefault()
		history.push('/campaigns/cupons')
	}
	const redirectEmail = e => {
		e.preventDefault()
		history.push('/campaigns/email')
	}
	const redirectSMS = e => {
		e.preventDefault()
		history.push('/campaigns/sms')
	}
	const redirectPush = e => {
		e.preventDefault()
		history.push('/campaigns/pushnotification')
	}
	const redirectCostumer = e => {
		e.preventDefault()
		history.push('/campaigns/customermanagement')
	}
	const redirectEndOrders = e => {
		e.preventDefault()
		history.push('/endorders')
	}
	const redirectMinePage = e => {
		e.preventDefault()
		history.push('/')
	}
	const redirectNewOrder = e => {
		e.preventDefault()
		history.push('/neworder')
	}
	const charts = e => {
		e.preventDefault()
		history.push('/charts')
	}

	return (
		<ul
			className={`navbar-nav shadow sidebar sidebar-lite accordion ${Toggled}`}
			id='accordionSidebar'>
			{/* Sidebar - Brand */}

			<li className='nav-item active '>
				<a
					className='nav-link '
					href=''
					onClick={e => {
						redirectNewOrder(e)
					}}>
					<i className='fas fa-laugh-wink' />
				</a>
			</li>
			{/* Divider */}
			<hr className='sidebar-divider my-0' />
			{/* Nav Item - Dashboard */}
			<li className='nav-item active '>
				<a
					className='nav-link '
					href=''
					onClick={e => {
						redirectNewOrder(e)
					}}>
					<i className='fas fa-cart-plus'></i>
				</a>
			</li>
			<li className='nav-item active'>
				<a
					className='nav-link'
					href=''
					onClick={e => {
						redirectOrders(e)
					}}>
					<i className='fas fa-list-ol'></i>
				</a>
			</li>
			{/* Divider */}
			<hr className='sidebar-divider my-0' />

			<li className='nav-item'>
				<a
					className='nav-link'
					href=''
					onClick={e => {
						redirectQR(e)
					}}>
					<i className='fas fa-qrcode'></i>
				</a>
			</li>
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#'
					data-toggle='collapse'
					data-target='#collapseTwo'
					aria-expanded='true'
					aria-controls='collapseTwo'>
					<i className='fas fa-fw fa-cog' />
				</a>
				<div
					id='collapseTwo'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'>
					<div className='bg-white py-2 collapse-inner rounded'>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectAddMeal(e)
							}}>
							Dodaj danie
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectAddCategory(e)
							}}>
							Dodaj kategorie
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectEditMenu(e)
							}}>
							Edycja menu
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectAddAdditions(e)
							}}>
							Dodatki/Zastawy
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectMenu(e)
							}}>
							Menu
						</a>
					</div>
				</div>
			</li>
			{/* Nav Item - Utilities Collapse Menu */}
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#'
					data-toggle='collapse'
					data-target='#collapseUtilities'
					aria-expanded='true'
					aria-controls='collapseUtilities'>
					<i className='fas fa-fw fa-wrench' />
				</a>
				<div
					id='collapseUtilities'
					className='collapse'
					aria-labelledby='headingUtilities'
					data-parent='#accordionSidebar'>
					<div className='bg-white py-2 collapse-inner rounded'>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectTexts(e)
							}}>
							Teksty
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectStyle(e)
							}}>
							Wygląd
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectAlerts(e)
							}}>
							Alerty
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectCompanyInfo(e)
							}}>
							Dane firmy
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectIcons(e)
							}}>
							Legenda
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectDelivery(e)
							}}>
							Strefa dostaw
						</a>
					</div>
				</div>
			</li>
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#'
					data-toggle='collapse'
					data-target='#collapseUtilities2'
					aria-expanded='true'
					aria-controls='collapseUtilities2'>
					<i className='fas fa-ad'></i>
				</a>
				<div
					id='collapseUtilities2'
					className='collapse'
					aria-labelledby='headingUtilities'
					data-parent='#accordionSidebar'>
					<div className='bg-white py-2 collapse-inner rounded'>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectCupons(e)
							}}>
							Kupony rabatowe
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectEmail(e)
							}}>
							Kampania emailowa
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectSMS(e)
							}}>
							Kampania SMS
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectPush(e)
							}}>
							Android/IOS
						</a>
						<a
							className='collapse-item'
							href=''
							onClick={e => {
								redirectCostumer(e)
							}}>
							Zarządzaj danymi klientów
						</a>
					</div>
				</div>
			</li>
			<li className='nav-item'>
				<a
					className='nav-link'
					href=''
					onClick={e => {
						redirectEndOrders(e)
					}}>
					<i className='fas fa-hourglass-end'></i>
				</a>
			</li>
			<li className='nav-item'>
				<a
					className='nav-link'
					href=''
					onClick={e => {
						charts(e)
					}}>
					<i className='fas fa-chart-pie'></i>
				</a>
			</li>
		</ul>
	)
}
export default withRouter(HeaderNew)
