import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import SingleOrder from "./SingleOrder";
import { SetLengthOrder, SetshowModal } from "../store/UserReducer";
import NavNew from './NavNew';
import { Howl } from "howler";
import HeaderNew from "../Components/HeaderNew";
import CircleCheck from "../img/circle-checked.svg"
import Circle from "../img/circle-x.svg"
import Discount from "../img/discount.svg"
import Icon from "../img/icon.svg"
import PinStart from "../img/pin-start.svg"
import Pin from "../img/pin.svg"
import Route from "../img/route.svg"

import "../CSS/Orders.css";
export default function Orders() {

  const ShowModal = useSelector((e) => e.UserReducer.showModal);
  const URL = useSelector((e) => e.UserReducer.url);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const SellectedOrder = useSelector((e) => e.UserReducer.sellecTedOrder);
  //const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const Bool = useSelector((e) => e.UserReducer.lengthOrder);
  const [Orderss, setOrderss] = useState();
  const [Orders2, setOrders] = useState([]);
  const [interval_counter, setIntervalCounter] = useState(0);

  const soundPlay = (src) => {
    const sound = new Howl({
      src,
      html5: true,
    });
    sound.play();
  };
  const changeState = () => {
    let d = new Date();
    let n = d.getTime();
    setOrderss(n);
    return true;
  };
  let temp_interval_counter = 0;
  const dispatch = useDispatch();
  useEffect(() => {
    clearInterval();
    add();
    //console.log(Bool);
    let int = setInterval(() => {
      changeState();
      add();
      temp_interval_counter++;
      setIntervalCounter(temp_interval_counter);
      console.log(`Interval counter: ${temp_interval_counter}`);
      changeState();
      SetLengthOrder(1, dispatch);
    }, 6000);
    return () => clearInterval(int);
  }, []);

  let pre_update_orders_count = 0;
  let not_first_run = false;
  const add = () => {
    //  //console.log(Orders2);
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);

        if (response.status === 200) {
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          // orders_array.map((element) => {
          console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });
          //console.log(pre_update_orders_count);
          changeState();
          setOrders(orders_array);
          if (
            pre_update_orders_count !== Object.keys(response.data).length &&
            not_first_run
          ) {
            if (changeState()) {
              changeState();
              soundPlay(`${URL}/mp3/ring.mp3`);
              changeState();
            }
            //console.log(pre_update_orders_count);
          }
          not_first_run = true;
          pre_update_orders_count = orders_array.length;
          //  //console.log("kurwaaa");
        }
        changeState();
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        changeState();
        change();
        setTimeout(() => {
          add();
        }, 500);
      });
  };

  const change = () => {
    console.log("kurwaaa");
    axios
      .get(`${URL}/get_orders.php`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        console.log(response.data);

        if (response.status === 200) {
          console.log("kurwaaa");
          let orders_array = [];
          Object.keys(response.data).forEach((element) => {
            orders_array.push(response.data[element]);
          });
          setOrders(orders_array);

          // orders_array.map((element) => {
          //console.log(orders_array);

          // SetOrders(element, dispatch);
          ////console.log(element);
          // });


        }
        changeState();
      })
      .catch((err) => {
        console.log(err.response);
        console.log(err.request);
        console.log("Error", err.message);
        changeState();
      });
  };
  // useEffect(() => {
  // if (ResteurantCode !== null) {
  // axios
  // .get(
  // `${SERVER_URL}/${ResteurantCode}/orders`,
  // {
  //  "Content-Type": "application/json",
  //}
  //)
  //.then((response) => {
  //  if (response.status === 200) {
  //   Object.keys(response.data).map((element) => {
  //   SetOrderss(response.data[element], dispatch);
  // setOrderss(response.data[element]);
  //  //console.log(element);
  // });
  // }
  //})
  //.catch((err) => {
  //  //console.log(err);
  //});
  // }
  //}, [ResteurantCode]);
  const [Window, setWindow] = useState(0)

  const windowSelectorOne = () => {

    setWindow(0)
  }
  const windowSelectorTwo = () => {

    setWindow(1)
  }
  const handleClose = (x) => SetshowModal(x, dispatch);

  return (
    <>

      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <div className="container-fluid pl-2 pr-0">
            <div className="wrapper d-block">

              <div
                className="row py-md-2 py-1 w-100 mx-0"
                style={{
                  height: window.innerHeight - 120,
                }}
              >
                <div className="col-12 px-0 mb-1 border-bottom">


                  <div class="collapse" id="collapseExample">
                    <div className="row mx-0 px-0">
                      <div className="col-4">

                        <p className="mb-0 Bold">Status zamówienia</p>
                        <button class="btn border-warning  text-warning py-0 px-1 Font15px mb-1"><i class="far fa-check-square"></i> Kolejka</button><br />
                        <button class="btn border-primary  text-primary py-0 px-1 Font15px mb-1"><i class="fas fa-concierge-bell"></i> Kuchnia</button><br />
                        <button class="btn border-success  text-success py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Gotowe</button><br />
                        <button class="btn border-danger  text-danger py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Zakończone</button><br />

                      </div>
                      <div className="col-4">
                        <p className="mb-0 Bold">Rodzaj dostawy</p>
                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Na miejscu</button><br />
                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Na wynos</button><br />
                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fas fa-route"></i> Dostawa</button><br />
                        <br />
                      </div>
                      <div className="col-4">

                        <p className="mb-0 Bold">Płatność</p>

                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Zapłacone</button><br />
                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Nie zapłacone</button><br />
                        <button class="btn border-dark  py-0 px-1 Font15px mb-1"><i class="fa fa-home"></i> Rabat</button><br />

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 px-0  h-100 "
                  style={{
                    height: window.innerHeight - 120,
                  }}
                >




                  <div
                    className="w-50 mx-0 float-left"

                  >
                    {Orders2.map((element, index) => {
                      if ((element.progress === "2")) {
                        return (
                          <SingleOrder
                            element={element}
                            b1={1}
                            b2={1}
                            b3={1}
                            func={change}
                          />
                        );
                      }
                    })}
                  </div>
                  <div
                    className="w-50 mx-0 float-left"

                  >
                    {Orders2.map((element, index) => {
                      if ((element.progress === "3")) {
                        return (
                          <SingleOrder
                            element={element}
                            b1={1}
                            b2={1}
                            b3={1}
                            func={change}
                          />
                        );
                      }
                    })}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal-90w">
        <Modal

          dialogClassName="custom-modal"
          onHide={() => handleClose(false)}
          aria-labelledby="example-custom-modal-styling-title"
          show={ShowModal}
          style={{
            width: '100%'
          }}
        >




          {SellectedOrder !== null ? <div className="card-body b-card py-0 px-0"
            style={{
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',

            }}
          >
            <div className="row bg-white px-0 mx-0"

            >
              <div className={!Window ? 'col-4 bg-white text-center py-3 pointer' : 'col-4 bg-secondary text-center py-3 pointer'} onClick={() => { windowSelectorOne() }}>
                Zamówienie
              </div>
              <div className={!Window ? 'col-4 bg-secondary text-center py-3 pointer' : 'col-4 bg-white text-center py-3 pointer'} onClick={() => { windowSelectorTwo() }}>
                Dostawa
              </div>

              <div className={!Window ? 'col-12' : 'col-12 d-none'} >

                <div className="row mx-0 px-3 pt-3 mb-2">
                  <div className="col-6 Font15px Thin">
                    ID zamówienia: {SellectedOrder.id}
                  </div>
                  <div className="col-6 mb-2">
                    <button type="button" class="btn btn-link float-right mx-2  Thin text-secondary">Usuń</button>
                    <button type="button" class="btn btn-link float-right mx-2  Thin text-secondary">Edytuj</button>


                  </div>


                  {

                    SellectedOrder.meal_options.map((element, index) => {

                      if (element.additions.length === 0) {
                        return <><div className="col-8 mb-1 border-bottom Font15px"> <span className="Regular lower">{index + 1}. {element.name} </span></div><div className="col-4 mb-1 border-bottom">
                          <p className="text-right mb-0 Font12px">Rabat 15% </p>
                          <p className="text-right  mb-0 Font12px "><s className="text-secondary Font10px mr-1">{element.price.toFixed(2)} zł </s>{element.price.toFixed(2)} zł</p>

                        </div></>
                      } else if (element.additions.length > 0) {
                        let additions = '';
                        element.additions.map((elemant) => {
                          additions = additions + `<div class="col-8 mb-1 pl-4 Thin Font15px">${elemant.name}</div><div class="col-4 mb-2 text-right Font12px">${elemant.price.toFixed(2)} zł</div>`
                        }

                        );

                        return (
                          <>
                            <div className="col-8 mb-1 Font15px ">
                              <span className="Regular lower">{index + 1}. {element.name}</span>
                            </div>
                            <div className="col-4 mb-1 Font15px">
                              <p className="text-right mb-0 Font12px">Rabat 15% </p>
                              <p className="text-right  mb-0 Font12px "><s className="text-secondary Font10px mr-1">{element.price.toFixed(2)} zł </s>{element.price.toFixed(2)} zł</p>

                            </div>
                            <div className="row mx-0 w-100 border-bottom" dangerouslySetInnerHTML={{ __html: additions }}></div>
                          </>
                        );
                      }

                    })
                  }
                  {
                    SellectedOrder.comment ? <div className="col-12 px-3 mb-3">
                      <p className="mb-1 Thin">Komentarz</p>
                      <p className=" ">{SellectedOrder.comment}
                      </p>
                    </div> : ''
                  }
                  <div className="col-12 px-3">
                    <p className="mb-0 ">Kod rabatowy: {SellectedOrder.promotion_code} (-18%) </p>
                    <p className="Thin mb-0">Kwota przed rabatem: 120zł</p>
                    <p className="Thin mb-0">Rabat: 20zł</p>
                    <p className="mb-0 ">Koszt ostateczny: 100zł</p>
                  </div>

                </div>
                <div className="col-12 px-3">
                  <div class="form-group px-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                      <label class="form-check-label text-success" for="invalidCheck2">
                        Zamowienie oplacone
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 w-100 mb-3">
                  <div className="col-6  text-center"> <button type="button" class="btn w-50 py-3 btn-success">Dostawa</button> </div>
                  <div className="col-6  text-center"> <button type="button" class="btn w-50 py-3 btn-danger">Zakończ</button> </div>
                </div>

              </div>
              <div className={Window ? 'col-12' : 'col-12 d-none'} >
                <div className="row mx-0 px-3 pt-3">
                  <div className="col-6 ">
                    ID zamówienia: {SellectedOrder.id}
                  </div>
                  <div className="col-6 ">
                    <button type="button" class="btn btn-link float-right mx-2">Usuń</button>
                    <button type="button" class="btn btn-link float-right mx-2">Edytuj</button>


                  </div>
                  <div className="col-12 px-3 mb-3">
                    <p className="mb-1 ">Kraków</p>
                    <p className="mb-1 ">ul. Kochanowskiego 56/9</p>
                    <p className="mb-1 ">698 - 968 - 012</p>
                    <p className="mb-1 Thin">Sebastianek Pulak</p>
                    <p className="mb-1 Thin">sebka@amorki.pl</p>
                  </div>

                  {
                    SellectedOrder.comment ? <div className="col-12 px-3 mb-1">
                      <p className="mb-1 Thin">Komentarz</p>
                      <p className="mb-0 ">{SellectedOrder.comment}
                      </p>
                    </div> : ''
                  }
                  <div className="col-12 px-3">
                    <p className="mb-0 ">Kod rabatowy: {SellectedOrder.promotion_code} (-18%) </p>
                    <p className="Thin mb-0">Kwota przed rabatem: 120zł</p>
                    <p className="Thin mb-0">Rabat: 20zł</p>
                    <p className="mb-0 text-danger">Zamówienie edytowane</p>
                    <p className="mb-0 ">Zapłacono: 80zł</p>
                    <p className="mb-0 ">Koszt ostateczny: 100zł</p>
                  </div>

                </div>
                <div className="col-12 px-3">
                  <div class="form-group px-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                      <label class="form-check-label text-success" for="invalidCheck2">
                        Zamowienie oplacone
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 w-100 mb-3">
                  <div className="col-6  text-center"> <button type="button" class="btn w-50 py-3 btn-success">Dostawa</button> </div>
                  <div className="col-6  text-center"> <button type="button" class="btn w-50 py-3 btn-danger">Zakończ</button> </div>
                </div>
              </div>
            </div>
          </div> : ''}
        </Modal>
      </div>
    </>
  );
}
