import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
function LoginPanel({ history }) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const [Login, setLogin] = useState("");
  const [Pass, setPass] = useState("");
  const [LoginState, setLoginState] = useState("");
  const ValidLogin = (e) => {
    setLogin(e.target.value);
    setLoginState('')
  };
  const ValidPass = (e) => {
    setPass(e.target.value);
    setLoginState('')
  };
  const something=(event)=> {
    if (event.keyCode === 13) {
      Submit(event)
    }
}
  const Submit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${SERVER_URL}/${ResteurantCode}/login`,
        {
          username: Login,
          password: Pass,
        },
        {
          "Content-Type": "application/json",
        }
      )
      .then((response) => {
        // console.log(response.data);
        setLoginState("Zalogowano");
        Cookies.set("IsLoggedIn", true);
        history.push("/");
      })

      .catch((err) => {
        // console.log(err.response);
        setLoginState("Błąd! Login lub hasło są nieprawidłowe");
      });
  };
  return (
    <>
     


      <div className="container">
        {/* Outer Row */}
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">

                  <div className="col-lg-12">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">FlexMenu</h1>
                      </div>
                      <form className="user">
                        <div className="form-group">
                          <input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Login" 
                             value={Login}
                             onChange={(e) => ValidLogin(e)}
                             onKeyDown={(e) => something(e) }
                          />
                        </div>
                        <div className="form-group">
                          <input type="password" className="form-control form-control-user" id="exampleInputPassword" placeholder="Password" 
                          value={Pass}
                          onChange={(e) => ValidPass(e)}
                          onKeyDown={(e) => something(e) }
                          />
                        </div>
                        <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input type="checkbox" className="custom-control-input" id="customCheck" />
                          </div>
                        </div>
                        <a href="" className="btn btn-primary btn-user btn-block"
                        onClick={(e)=>Submit(e)}
                       
                        >
                          Zaloguj
                        </a>
                        {
                          LoginState!==''?<div class="alert alert-danger mt-2" role="alert">
                          {LoginState}
                        </div>:''
                        }
                        


                      </form>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(LoginPanel);
