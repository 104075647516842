import React, { useEffect, useState } from "react";

import axios from "axios";
import NavNew from "./NavNew";
import { useSelector } from "react-redux";
import HeaderNew from "../Components/HeaderNew";

import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
export default function IconNames() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  const [Icons_legend_title, setIcons_legend_title] = useState("");
  const [Icon_1_title, setIcon_1_title] = useState("");
  const [Icon_2_title, setIcon_2_title] = useState("");
  const [Icon_3_title, setIcon_3_title] = useState("");
  const [Icon_4_title, setIcon_4_title] = useState("");
  const [Icon_5_title, setIcon_5_title] = useState("");
  const [Icon_6_title, setIcon_6_title] = useState("");
  const [Img1, setImg1] = useState("");
  const [Img2, setImg2] = useState("");
  const [Img3, setImg3] = useState("");
  const [Img4, setImg4] = useState("");
  const [Img5, setImg5] = useState("");
  const [Img6, setImg6] = useState("");
  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setIcons_legend_title(UserInfo.editable_texts.icons_legend_title);
      setIcon_1_title(UserInfo.editable_texts.icon_1_title);
      setIcon_2_title(UserInfo.editable_texts.icon_2_title);
      setIcon_3_title(UserInfo.editable_texts.icon_3_title);
      setIcon_4_title(UserInfo.editable_texts.icon_4_title);
      setIcon_5_title(UserInfo.editable_texts.icon_5_title);
      setIcon_6_title(UserInfo.editable_texts.icon_6_title);
    }
    if (UserInfo.img !== undefined) {
      setImg1(UserInfo.img.icon1);
      setImg2(UserInfo.img.icon2);
      setImg3(UserInfo.img.icon3);
      setImg4(UserInfo.img.icon4);
      setImg5(UserInfo.img.icon5);
      setImg6(UserInfo.img.icon6);
    }
  }, [UserInfo]);

  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  const setWebsiteData = () => {
    let json = {
      editable_texts: {
        icons_legend_title: Icons_legend_title,
        icon_1_title: Icon_1_title,
        icon_2_title: Icon_2_title,
        icon_3_title: Icon_3_title,
        icon_4_title: Icon_4_title,
        icon_5_title: Icon_5_title,
        icon_6_title: Icon_6_title,
      },
    };

    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column bg-white">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Strefy dostaw", path: "/customize/delivery" },
              { name: "Dane firmy", path: "/customize/companyinfo" },
              { name: "Alerty", path: "/customize/alerts" },
              { name: "Wygląd", path: "/customize/style" },
              { name: "Dodatkowe teksty", path: "/customize/texts" },
              { name: "Legenda", path: "/customize/icons" },
            ]}
          />
          <NavNew />
          <div className="max-w-7xl mx-auto bg-white ">
            <div className="pl-5 pt-1 pr-5  ">
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Legenda
                </p>
              </div>
              <label className="mb-0">Nazwa legendy</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={Icons_legend_title}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setIcons_legend_title(e.target.value);
                  }}
                />
              </div>

              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img1}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_1_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_1_title(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img2}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_2_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_2_title(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img3}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_3_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_3_title(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img4}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_4_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_4_title(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img5}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_5_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_5_title(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row px-0 mx-0">
                <div className="col-2 px-0  mx-0">
                  {" "}
                  <img
                    src={URL + Img6}
                    alt="icon"
                    style={{
                      width: "37px",
                    }}
                  />
                </div>
                <div className="col-10 px-0  mx-0">
                  <div class="mb-3 relative rounded-md shadow-sm ">
                    <input
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder=""
                      value={Icon_6_title}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setIcon_6_title(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex justify-between">
                      <div></div>
                      <div>
                  <button
                    type="button"
                    className=" content-end px-3  py-2 border w-36 border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setWebsiteData()}
                    disabled={!Active}
                  >
                    {Text}
                  </button>
                  <p className=" mx-2">{ComunicateText}</p>
                  </div>
              </div>
                </div>
             
              </div>
              
             
            </div>
          </div>

   
        </div>
      </div>
    </>
  );
}
