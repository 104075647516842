import React, { useEffect, useState } from "react";

import FileBase64 from "react-file-base64";
import axios from "axios";
import NavNew from "./NavNew";
import { useSelector } from "react-redux";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
export default function Style() {
  const URL = useSelector((e) => e.UserReducer.url);
  const UserInfo = useSelector((e) => e.UserReducer.allData);

  const [FileText, setFileText] = useState(null);
  const [FileName, setFileName] = useState(null);
  const [ShowMoreOptionsButtonText, setShowMoreOptionsButtonText] =
    useState("");
  const File = (files) => {
    setFileText(files.base64);
    setFileName(files.name.replace(/\s+/g, ""));
  };
  const [Additions_font_color, setAdditions_font_color] = useState("");
  const [Additions_start_color, setAdditions_start_color] = useState("");
  const [HeaderText1, setHeaderText1] = useState("");
  const [HeaderText2, setHeaderText2] = useState("");
  const [CodeInpotPleaceholder, setCodeInpotPleaceholder] = useState("");
  const [ClientTextPleaceholder, setClientTextPleaceholder] = useState("");
  const [DeliveryAdresHeaderText, setDeliveryAdresHeaderText] = useState("");
  const [ContactHeaderText, setContactHeaderText] = useState("");
  const [SubmitButtonText, setSubmitButtonText] = useState("");
  const [MyOrderHeaderText, setMyOrderHeaderText] = useState("");

  const [backgroundType, setBackgroundType] = useState("gradient");

  const [Additions_stop_color, setAdditions_stop_color] = useState("");
  const [Base_font_color, setBase_font_color] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  useEffect(() => {
    if (UserInfo.colors !== undefined) {
      setShowMoreOptionsButtonText(UserInfo.editable_texts.meal_sizes_button);
      setHeaderText1(UserInfo.editable_texts.header_text_1);
      setHeaderText2(UserInfo.editable_texts.header_text_2);
      setClientTextPleaceholder(UserInfo.editable_texts.comment_to_order);
      setDeliveryAdresHeaderText(
        UserInfo.editable_texts.delivery_address_header
      );
      setContactHeaderText(UserInfo.editable_texts.delivery_client_contact);
      setSubmitButtonText(UserInfo.editable_texts.confirm_order_button);
      setMyOrderHeaderText(UserInfo.editable_texts.my_order_label);

      setAdditions_font_color(UserInfo.colors.additions_font_color);
      setAdditions_start_color(UserInfo.colors.additions_start_color);
      setAdditions_stop_color(UserInfo.colors.additions_stop_color);
      setBase_font_color(UserInfo.colors.base_font_color);
      setCodeInpotPleaceholder(UserInfo.editable_texts.promo_code_label);
    }
  }, [UserInfo]);

  const setBackgroundTypeGradient = () => {
    setBackgroundType("gradient");
  };

  const setBackgroundTypeFlat = () => {
    setBackgroundType("flat");
  };

  const setWebsiteData = () => {
    let json = {};
    if (FileName !== null) {
      json = {
        colors: {
          additions_font_color: Additions_font_color,
          additions_start_color: Additions_start_color,
          additions_stop_color: Additions_stop_color,
          base_font_color: Base_font_color,
        },
        img: {
          background: {
            photo_url: "/img/restaurant/" + FileName,
            encoded_photo: FileText,
          },
        },
        editable_texts: {
          header_text_1: HeaderText1,
          header_text_2: HeaderText2,
          meal_sizes_button: ShowMoreOptionsButtonText,

          my_order_label: MyOrderHeaderText,
          promo_code_label: CodeInpotPleaceholder,
          comment_to_order: ClientTextPleaceholder,
          delivery_address_header: DeliveryAdresHeaderText,
          delivery_client_contact: ContactHeaderText,
          confirm_order_button: SubmitButtonText,
        },
      };
    } else if (FileName === null) {
      json = {
        colors: {
          additions_font_color: Additions_font_color,
          additions_start_color: Additions_start_color,
          additions_stop_color: Additions_stop_color,
          base_font_color: Base_font_color,
        },
      };
    }
    //console.log(json);
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className=" d-flex flex-column bg-white ">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Strefy dostaw", path: "/customize/delivery" },
              { name: "Dane firmy", path: "/customize/companyinfo" },
              { name: "Alerty", path: "/customize/alerts" },
              { name: "Wygląd", path: "/customize/style" },
              { name: "Dodatkowe teksty", path: "/customize/texts" },
              { name: "Legenda", path: "/customize/icons" },
            ]}
          />
          <NavNew />

          <div className="max-w-7xl mx-auto ">
            <div className="pl-5 pt-1 pr-5  ">
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Kolorystyka tła
                </p>
              </div>

              <fieldset className="mt-2 justify-between ">
                <div
                  key="gradient"
                  className="flex items-center justify-between  "
                >
                  <input
                    id="gradient"
                    name="notification-method"
                    type="radio"
                    defaultChecked={true}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    onClick={setBackgroundTypeGradient}
                  />
                  <label
                    htmlFor="gradient"
                    className="ml-3 block text-sm font-medium text-gray-700 mr-8"
                  >
                    Gradient
                  </label>

                  <div class="input-group mb-2">
                    <input
                      type="text"
                      disabled={backgroundType !== "gradient"}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full mx-2  pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Kolor początkowy"
                      value={Additions_start_color}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setAdditions_start_color(e.target.value);
                      }}
                    />
                  </div>
                  <div class="input-group mb-2 ">
                    <input
                      type="text"
                      disabled={backgroundType !== "gradient"}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full ml-2 pl-7  pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Kolor końcowwy"
                      value={Additions_stop_color}
                      id="basic-url"
                      aria-describedby="basic-addon3"
                      onChange={(e) => {
                        setAdditions_stop_color(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div key="flat" className="flex items-center justify-between  ">
                  <input
                    id="flat"
                    name="notification-method"
                    type="radio"
                    onClick={setBackgroundTypeFlat}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />

                  <label
                    htmlFor="flat"
                    className="ml-3 block text-sm font-medium text-gray-700 mr-8"
                  >
                    Jednolita
                  </label>
                  <div class="input-group mb-2 ">
                    <input
                      type="text"
                      disabled={backgroundType !== "flat"}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full ml-2   pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                      placeholder="Kolor "
                      id="basic-url"
                      aria-describedby="basic-addon3"
                    />
                  </div>
                </div>
              </fieldset>
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Kolorystyka czcionki
                </p>
              </div>
              <label className="mb-0">Podstawowy kolor</label>

              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={Base_font_color}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setBase_font_color(e.target.value);
                  }}
                />
              </div>

              <label className="mb-0">Wyróżniony kolor czcionki</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={Additions_font_color}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setAdditions_font_color(e.target.value);
                  }}
                />
              </div>
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Teksty
                </p>
              </div>
              <label className="mb-0">Przycisk rozwijający danie</label>
              <div class="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={ShowMoreOptionsButtonText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setShowMoreOptionsButtonText(e.target.value);
                  }}
                />
              </div>

              <label className="mb-0">Nagłówek pierwszy</label>
              <div class="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={HeaderText1}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setHeaderText1(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Nagłówek drugi</label>
              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={HeaderText2}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setHeaderText2(e.target.value);
                  }}
                />
              </div>

              <label className="mb-0">Pole na kod promocyjny</label>
              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={CodeInpotPleaceholder}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setCodeInpotPleaceholder(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Pole na uwagi do zamówienia</label>
              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={ClientTextPleaceholder}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setClientTextPleaceholder(e.target.value);
                  }}
                />
              </div>

              <label className="mb-0">Nagłówek sekcji z danymi dostawy</label>
              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={DeliveryAdresHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setDeliveryAdresHeaderText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">
                {" "}
                Nagłówek sekcji z danymi kontaktowymi{" "}
              </label>

              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={ContactHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setContactHeaderText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Przycisk potwierdzający zamówienie</label>

              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={SubmitButtonText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setSubmitButtonText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Nagłówek podsumowania zamówienia</label>
              <div className="mb-3 relative rounded-md shadow-sm">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={MyOrderHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setMyOrderHeaderText(e.target.value);
                  }}
                />
              </div>
              <div className="  fixed bottom-1 right-2  w-1360 mb-4 ">
                <button
                  type="button "
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setWebsiteData()}
                  disabled={!Active}
                >
                  {Text}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
