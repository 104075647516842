import React, { useState, useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Circle, Marker } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const position = [50.04678745, 19.8613553]

export default function GoogleMaps({ranges}) {
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [radiuses, setRadiuses] = useState([]);

  // const radiusJson = {
  //   'green': {color: 'green', maxRadius: 3000, fillOpacity: 0.2, weight: 1},
  //   'blue': {color: 'blue', maxRadius: 5000, fillOpacity: 0.1, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  //   'red': {color: 'red', maxRadius: 8000, fillOpacity: 0.05, weight: 1},
  // }

  useEffect(() => {
    console.log(ranges);
    setDeliveryZones(ranges);
  }, []);

  useEffect(() => {
    setDeliveryZones(ranges);
    let tempRadius = [];
    let finalRadius = [];
    Object.keys(ranges).map(key => {
      tempRadius.push(parseInt(ranges[key].max_distance));
    })
    tempRadius.sort((a, b) => b - a);
    // let greenZone = [];
    // let blueZone = [];
    // let redZone = [];
    // tempRadius.forEach(element => {
    //   if (element < radiusJson['green'].maxRadius) {
    //     greenZone.push({radius: element, settings: radiusJson['green']});
    //   } else if (element > radiusJson['green'].maxRadius && element < radiusJson['blue'].maxRadius) {
    //     blueZone.push({radius: element, settings: radiusJson['blue']});
    //   } else {
    //     redZone.push({radius: element, settings: radiusJson['red']});
    //   }
    // });
    // finalRadius.push(greenZone[greenZone.length-1]);
    // finalRadius.push(blueZone[blueZone.length-1]);
    // finalRadius.push(redZone[redZone.length-1]);
    // setRadiuses(finalRadius);
    setRadiuses(tempRadius);
  }, [ranges]);

    const radiusZones = () => {
    let opacity = 0;
    const radiusList = radiuses.map((circle, index) => {
      if (circle !== undefined) {
        opacity += 0.05;
        console.log(opacity);
        return (
          <Circle
          center={position}
          pathOptions={{color: '#' + Math.random().toString(16).substr(-6), fillOpacity: opacity, weight: 2 }}
          radius={circle / 1.7}
        />
       )
      }
      return (<></>)
    });
    return radiusList;
  }

  return (
    <MapContainer center={position} zoom={14} style={{ width: '100%', height: '600px' }}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      className="map-tiles"
    />
    <Marker position={position}/>
      {radiusZones()}
  </MapContainer>
  )
}