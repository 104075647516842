export const ADD_TO_ORDER = "ADD_TO_ORDER";
export const DELETE_FROM_ORDER = "DELETE_FROM_ORDER";
export const SET_AT_LOCATION = "SET_AT_LOCATION";
export const SET_DELIVERY = "SET_DELIVERY";
const initialState = {
  order: [],
  aditionsCategory: {},
  atLocation: null,
  delivery: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_ORDER": {
      const newOrder = action.payload
      let tempOrderList = state.order;
      tempOrderList.push(newOrder);
      return {
        ...state,
        order: [...tempOrderList],
      };
    }
    case "DELETE_FROM_ORDER": {
        const meal = action.payload
        let tempOrderList = state.order;
        const index = state.order.indexOf(meal);
        if (index > -1) {
            tempOrderList.splice(index, 1);
          }
        return {
          ...state,
          order: [...tempOrderList],
        };
    }
    case "SET_AT_LOCATION": {
      return {
        ...state,
        atLocation: action.payload,
      };
    }
    case "SET_DELIVERY": {
      return {
        ...state,
        delivery: action.payload,
      };
    }
    default: {
        // console.log(`Unknown action ${action.type}`);
        return {
          ...state,
        };
      }
  }
};

export const addMeal = (order, dispatch) => {
  if (dispatch({ type: ADD_TO_ORDER, payload: order })) {
    return true;
  }
};

export const deleteMeal = (order, dispatch) => {
    if (dispatch({ type: DELETE_FROM_ORDER, payload: order })) {
      return true;
    }
  };

  export const SetaAtLocation = (atLocation, dispatch) => {
    if (dispatch({ type: SET_AT_LOCATION, payload: atLocation })) {
      return true;
    }
  };
  export const setDelivery = (delivery, dispatch) => {
    if (dispatch({ type: SET_DELIVERY, payload: delivery })) {
      return true;
    }
  };
