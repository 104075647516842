import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
export default function CostumerInfo({ e, func }) {
  const URL = useSelector((e) => e.UserReducer.url);
  const deleteCostumer = (id) => {
    axios
      .get(`${URL}/sqlite/delete_marketing_permit.php/?phone_number=${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        if ((response.status = 200)) {
          // console.log(response);
          func();
        }
      })

      .catch((err) => {
        //  console.log(err.response);
      });
  };

  return (
    <div className="w-full ">
      <div className="flex border-bottom justify-between justify-items-start  ">
        <div className="flex mb-2 mt-2 ">
          <div className=" text-base w-44 mr-11  font-weight:400 d-flex ">
            {e.email}
          </div>
          <div className=" text-base w-44 ml-10  d-flex">{e.phone_number}</div>
          <div className="  w-44 d-flex ml-4  ">
            <span>
              <p className="mb-0">{e.delivery_city}</p>
              <p className="mb-0">{e.delivery_address}</p>
            </span>
          </div>
        </div>
        <div className=" mt-3">
          <button
            type="button"
            className=" h-8 items-center px-3 w-24    text-sm leading-4 font-medium rounded-md shadow-sm hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => deleteCostumer(e.phone_number)}
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  );
}
