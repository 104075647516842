import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import MealONOFF from "./MealONOFF";

export function Meals(props) {
  const ResteurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const SERVER_URL = useSelector((e) => e.UserReducer.server_URL);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [selectedDishID, setselectedDishID] = useState("");

  const redirectEditMeal = (id) => {
    props.history.push(`/menu/editmenu/editmeal/${id}/${props.kategoryID}`);
  };

  const removeMeal = (id) => {
    //console.log(id);
    axios
      .delete(`${SERVER_URL}/${ResteurantCode}/meal_base/${id}`, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 204) {
          //console.log(response);
          window.location.reload();
          // setActive(true);
        }
      })
      .catch((err) => {
        // setActive(true);
        //console.log(err.response);
      });
  };

  return (
    <>
      {props.meals.map((element) => {
        let styleBg = "flex align-middle hover:bg-lightgray  bg-lightIndigo ";

        selectedDishID == element.name &&
        props.selectedCategory === props.categoryID
          ? (styleBg = "flex justify-between align-middle hover:bg-lightgray  bg-lightIndigo ")
          : (styleBg = "flex justify-between align-middle hover:bg-lightgray   ");

        // tu trzeba zaczytać ile jest opcji dania
        return (
          <>
            <div
              className={styleBg}
              onClick={() => {
                setselectedDishID(element.name);
                props.selectCategory({
                  kategoryID: props.kategoryID,
                  dish: element.name,
                  dishId: element.id,
                });
              }}
            >
               <div className=" flex">
                <div className=" ml-4 mt-1 ">
                  {!element.is_active ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.8974 7.10244L7.10244 10.8974C6.61494 10.4099 6.31494 9.74244 6.31494 8.99994C6.31494 7.51494 7.51494 6.31494 8.99994 6.31494C9.74244 6.31494 10.4099 6.61494 10.8974 7.10244Z"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.3649 4.32749C12.0524 3.33749 10.5524 2.79749 8.99988 2.79749C6.35238 2.79749 3.88488 4.35749 2.16738 7.05749C1.49238 8.11499 1.49238 9.89249 2.16738 10.95C2.75988 11.88 3.44988 12.6825 4.19988 13.3275"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.31494 14.6475C7.16994 15.0075 8.07744 15.2025 8.99994 15.2025C11.6474 15.2025 14.1149 13.6425 15.8324 10.9425C16.5074 9.88501 16.5074 8.10751 15.8324 7.05001C15.5849 6.66001 15.3149 6.29251 15.0374 5.94751"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.6326 9.52502C11.4376 10.5825 10.5751 11.445 9.51758 11.64"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.1025 10.8975L1.5 16.5"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 1.5L10.8975 7.1025"
                        stroke="#9CA3AF"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : element.is_promoted ? (
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2974 2.63248L11.6174 5.27248C11.7974 5.63998 12.2774 5.99248 12.6824 6.05998L15.0749 6.45748C16.6049 6.71248 16.9649 7.82248 15.8624 8.91748L14.0024 10.7775C13.6874 11.0925 13.5149 11.7 13.6124 12.135L14.1449 14.4375C14.5649 16.26 13.5974 16.965 11.9849 16.0125L9.74243 14.685C9.33743 14.445 8.66993 14.445 8.25743 14.685L6.01493 16.0125C4.40993 16.965 3.43493 16.2525 3.85493 14.4375L4.38743 12.135C4.48493 11.7 4.31243 11.0925 3.99743 10.7775L2.13743 8.91748C1.04243 7.82248 1.39493 6.71248 2.92493 6.45748L5.31743 6.05998C5.71493 5.99248 6.19493 5.63998 6.37493 5.27248L7.69493 2.63248C8.41493 1.19998 9.58493 1.19998 10.2974 2.63248Z"
                        fill="#FBBF24"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7299 2.51001L14.4899 6.03001C14.7299 6.52002 15.3699 6.99001 15.9099 7.08001L19.0999 7.61001C21.1399 7.95001 21.6199 9.43001 20.1499 10.89L17.6699 13.37C17.2499 13.79 17.0199 14.6 17.1499 15.18L17.8599 18.25C18.4199 20.68 17.1299 21.62 14.9799 20.35L11.9899 18.58C11.4499 18.26 10.5599 18.26 10.0099 18.58L7.01991 20.35C4.87991 21.62 3.57991 20.67 4.13991 18.25L4.84991 15.18C4.97991 14.6 4.74991 13.79 4.32991 13.37L1.84991 10.89C0.389909 9.43001 0.859909 7.95001 2.89991 7.61001L6.08991 7.08001C6.61991 6.99001 7.25991 6.52002 7.49991 6.03001L9.25991 2.51001C10.2199 0.600015 11.7799 0.600015 12.7299 2.51001Z"
                        stroke="#292D32"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <div className="ml-2 text-darkgray align-middle   font-bold text-xs text-center py-1 font-weight: 200 font-i  ">
                  {element.name}
                </div>
                </div>



                <div className="flex">
                  {element.meal_options.map((el,i) => {
                 
                 if( (element.meal_options.length - 1  )> i){
                  return (<p className="mx-1 text-sm ">{ el.price}  | </p>)

                }
                return (<p className="ml-1 mr-3 text-sm ">{el.price}  </p>)
                    
                  })}


                     
                  


                  

              
                </div>
           


            </div>

            <div className="col-12 my-1 px-0 mx-0 "></div>
          </>
        );
      })}
    </>
  );
}
export default withRouter(Meals);
