import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { propTypes } from "qrcode.react";

export default function EditSelectedMealForm({
  category_id,
  meal_base_id,
  meal_option_index,
  refresh_indexes,
  sizeAdjustable,
}) {
  const RestaurantCode = useSelector((e) => e.UserReducer.resteurantCode);
  const Menu = useSelector((e) => e.MenuReducer.menuToEdit);
  const [MealOptionName, setMealOptionName] = useState("");
  const [Size, setSize] = useState("");
  const [Kcal, setKcal] = useState("");
  const [Price, setPrice] = useState(null);
  const [IsOptionActive, setIsOptionActive] = useState(null);
  const [MealName, setMealName] = useState("");
  const [Ingreadients, setIngreadients] = useState("");
  const [PhotoUrl, setPhotoUrl] = useState("");
  const [IsPromoted, setIsPromoted] = useState(null);
  const [IsActive, setIsActive] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [MealBaseIndex, setMealBaseIndex] = useState(0);
  const [MealBase, setMealBase] = useState({});

  const [MealOptionIndex, setMealOptionIndex] = useState(0);
  const [MealOption, setMealOption] = useState({});

  const [DeleteOptionText, setDeleteOptionText] = useState("Usuń opcje");
  const dispatch = useDispatch();
  const [Category_id, setCategory_id] = useState(null);
  const [Meal_base_id, setMeal_base_id] = useState(null);
  const [Meal_option_index, setMeal_option_index] = useState(null);

  const [ZL, setZL] = useState("");
  const [GR, setGR] = useState("");

  const ZLValid = (value) => {
    let v = value.replace(/\D/, "");
    setZL(v);
    setPrice(v + "." + GR);
  };
  const PriceValid = (value) => {

 
  const patt = /[^(\d+)\.(\d+)]/g;
  let v = value.replace(patt, '');
  v = v.replace('..','.')
 
 
    setPrice(v);
  };

  const vaidateCal=(value) =>{
    const patt = /[^(\d+)\.(\d+)]/g;
    let v = value.replace(patt, '');
    v = v.replace('..','.')


    setKcal(v);
  }


  const GRValid = (value) => {
    let v = value.replace(/\D/, "");
    if (v.length <= 2) {
      setGR(v);
      setPrice(ZL + "." + v);
    }
  };

  useEffect(() => {
    setCategory_id(category_id);
    setMeal_base_id(meal_base_id);
    setMeal_option_index(meal_option_index);
  }, [category_id, meal_base_id, meal_option_index]);

  useEffect(() => {
    if (
      Menu !== null &&
      Category_id !== null &&
      Meal_base_id !== null &&
      Meal_option_index !== null
    ) {
      let meal_base_index = Menu.meal_categories[
        Category_id
      ].meal_bases.findIndex(function (meal_base) {
        return meal_base.id == Meal_base_id;
      });
      setMealBaseIndex(meal_base_index);
      setMealOptionIndex(Meal_option_index);
      setMealOptionName(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].name
      );
      setSize(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].size
      );
      setKcal(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].kcal
      );
      let price =
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].price;
      setPrice(price);
      SetZL_GR(price);
      setIsOptionActive(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index].is_active
      );
      setMealBase(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
      );
      setMealOption(
        Menu.meal_categories[Category_id].meal_bases[meal_base_index]
          .meal_options[Meal_option_index]
      );
    }
  }, [Menu, Category_id, Meal_base_id, Meal_option_index]);

  function EditMealOption(meal_option) {
    meal_option.name = MealOptionName;
    meal_option.size = Size;
    meal_option.kcal = Kcal;
    meal_option.price = Price;
    meal_option.is_active = IsOptionActive;
    // not implemented on frontend yet
    //menu[Category_id][index].menu_position = MenuPosition

    return meal_option;
  }

  function SaveMealOption(meal_option) {
    setMealOption(meal_option);
    Menu.meal_categories[Category_id].meal_bases[MealBaseIndex].meal_options[
      Meal_option_index
    ] = meal_option;
  }

  function DeleteMealOption() {
    Menu.meal_categories[Category_id].meal_bases[
      MealBaseIndex
    ].meal_options.splice(MealOptionIndex, 1);
    refresh_indexes(MealBaseIndex);
  }

  function SetZL_GR(price) {
    if (price !== null) {
      let zl_gr = String(price).split(".");
      setZL(zl_gr[0]);
      if (zl_gr[1] === undefined) {
        setGR("");
      } else {
        setGR(zl_gr[1]);
      }
    }
  }

  return (
    <div className="  border-bottom py-4">
      <div className="flex">
        <div className="w-183px mr-3">
          <label className="mb-0   text-gray-500">Nazwa wariantu</label>
          <input
            type="text"
            
            className="focus:ring-indigo-500 focus:border-indigo-500 pl-1 block w-full  text-sm  border-borderGray rounded-md"
            value={MealOptionName}
            placeholder="Nazwa"
            onChange={(e) => {
              //setMealOptionName(e.target.value);
              
            }}
            onBlur={(e) => {
              SaveMealOption(EditMealOption(MealOption));
            }}
          />
        </div>

        <div className="w-183px mr-3">
          <label className="mb-0   text-gray-500">Cena</label>
          <input
            type="text"
            className="form-control focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm  border-borderGray rounded-md"
            placeholder="zł"
            value={Price}
            onChange={(e) => {
              // ZLValid(e.target.value);
              PriceValid(e.target.value);
            }}
            onBlur={(e) => {
              SaveMealOption(EditMealOption(MealOption));
            }}
          />{" "}
        </div>

        <button
          className="font-bold"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {" "}
          ...
        </button>
      </div>

      {sizeAdjustable && (
        <div className="flex mt-2">
          <div className="w-183px mr-3">
            <label className="mb-0   text-gray-500">Rozmiar</label>
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm  border-borderGray rounded-md"
              value={Size}
              placeholder="Opcja/rozmiar"
              onChange={(e) => {
                setSize(e.target.value);
              }}
              onBlur={(e) => {
                SaveMealOption(EditMealOption(MealOption));
              }}
            />
          </div>

          <div className="w-183px mr-3">
            <label className="mb-0   text-gray-500">Kalorie</label>
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  sm:text-sm  border-borderGray rounded-md"
              value={Kcal}
              placeholder="kcal"
              onChange={(e) => {
                vaidateCal(e.target.value);
              }}
              onBlur={(e) => {
                SaveMealOption(EditMealOption(MealOption));
              }}
            />
          </div>
        </div>
      )}

      {showModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-white opacity-30"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                <div className="mt-3 sm:flex justify-center">
                  <div className="flex justify-center  items-center ">
                    <div class="custom-control  custom-checkbox mr-sm-2 mr-4">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id={Meal_option_index}
                        checked={IsOptionActive}
                        onChange={(e) => setIsOptionActive(e.target.checked)}
                        onBlur={(e) => {
                          SaveMealOption(EditMealOption(MealOption));
                        }}
                      />
                      <label
                        class="custom-control-label  "
                        for={Meal_option_index}
                      >
                        {" "}
                        Aktywne{" "}
                      </label>
                    </div>

                    <button
                      type="button"
                      className="h-8 px-1    text-sm leading-4 font-medium rounded-md   hover:bg-lightIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={DeleteMealOption}
                    >
                      {DeleteOptionText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
