import React from 'react'
import { HomeIcon } from '@heroicons/react/solid'

const pages = [
	{ name: 'Ustawienia', href: '#', current: false },
	{ name: 'Cenniki dostaw', href: '#', current: true },
]

export default function BreadcrumbNav() {
	return (
		<div>
			<nav className='flex' aria-label='Breadcrumb'>
				<ol role='list' className='flex items-center '>
					<li>
						<div className='mr-4'>
							<a
								href='#'
								className='text-darkgray-400 hover:text-darkgray-500 '>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='h-5 w-5'
									fill='none'
									viewBox='0 0 24 24'
									stroke='#111827'
									strokeWidth={2}>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M15 19l-7-7 7-7'
									/>
								</svg>
								<span className='sr-only'>Home</span>
							</a>
						</div>
					</li>
					{pages.map((page, index) => (
						<li key={page.name}>
							<div className='flex items-center'>
								{index != 1 ? (
									''
								) : (
									<svg
										className='flex-shrink-0 h-5 w-5 text-darkgray-300'
										xmlns='http://www.w3.org/2000/svg'
										fill='#111827'
										viewBox='0 0 20 20'
										aria-hidden='true'>
										<path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
									</svg>
								)}
								<a
									href={page.href}
									className='ml-0 text-sm font-medium text-darkgray hover:text-darkgray-700'
									aria-current={page.current ? 'page' : undefined}>
									{page.name}
								</a>{' '}
							</div>
						</li>
					))}
				</ol>
			</nav>
		</div>
	)
}
