import React, { useEffect, useState } from "react";

import axios from "axios";
import NavNew from "./NavNew";
import { useSelector } from "react-redux";
import HeaderNew from "../Components/HeaderNew";
import SettingsNavNew from "./TailwindComponents/SettingsNavNew/SettingsNavNew";
const Texts = () => {
  const URL = useSelector((e) => e.UserReducer.url);

  const [HeaderText1, setHeaderText1] = useState("");
  const [HeaderText2, setHeaderText2] = useState("");
  const [ShowMoreOptionsButtonText, setShowMoreOptionsButtonText] =
    useState("");

  const [HeaderConfirmSectionText, setHeaderConfirmSectionText] = useState("");
  const [AlertCloseText, setAlertCloseText] = useState("");

  const [MyOrderHeaderText, setMyOrderHeaderText] = useState("");
  const [CodeInpotPleaceholder, setCodeInpotPleaceholder] = useState("");
  const [ClientTextPleaceholder, setClientTextPleaceholder] = useState("");
  const [DeliveryAdresHeaderText, setDeliveryAdresHeaderText] = useState("");
  const [ContactHeaderText, setContactHeaderText] = useState("");
  const [SubmitButtonText, setSubmitButtonText] = useState("");

  const [OpenHoursInformationText, setOpenHoursInformationText] = useState("");
  const [SocialMediaHeaderText, setSocialMediaHeaderText] = useState("");
  const [AcceptOrderHeaderText, setAcceptOrderHeaderText] = useState("");
  const [AcceptOrderComunicatText, setAcceptOrderComunicatText] = useState("");
  const [DenyOrderHeaderText, setDenyOrderHeaderText] = useState("");
  const [DenyOrdedComunicatText, setDenyOrdedComunicatText] = useState("");
  const [InformationTitle, setInformationTitle] = useState("");
  const [InformationText, setInformationText] = useState("");
  const [Active, setActive] = useState(true);
  const [Text, setText] = useState("Zapisz zmiany");
  const [ComunicateText, setComunicateText] = useState("");
  const [SMSText, setSMSText] = useState("");
  const UserInfo = useSelector((e) => e.UserReducer.allData);
  const [G_A_ID, setG_A_ID] = useState("");
  const [FCM_Server_key, setFCM_Server_key] = useState("");
  const [FBLink, setFBLink] = useState("");
  const [InstLink, setInstLink] = useState("");

  useEffect(() => {
    if (UserInfo.editable_texts !== undefined) {
      setHeaderText1(UserInfo.editable_texts.header_text_1);
      setHeaderText2(UserInfo.editable_texts.header_text_2);
      setShowMoreOptionsButtonText(UserInfo.editable_texts.meal_sizes_button);
      //setCartButtonTExt()
      //setCartSectionText();
      setHeaderConfirmSectionText(UserInfo.editable_texts.order_summary);
      setAlertCloseText(UserInfo.editable_texts.restaurant_auto_closed_alert);
      setMyOrderHeaderText(UserInfo.editable_texts.my_order_label);
      setDeliveryAdresHeaderText(
        UserInfo.editable_texts.delivery_address_header
      );
      setFBLink(UserInfo.editable_texts.facebook_link);
      setInstLink(UserInfo.editable_texts.instagram_link);
      setCodeInpotPleaceholder(UserInfo.editable_texts.promo_code_label);
      setClientTextPleaceholder(UserInfo.editable_texts.comment_to_order);
      setContactHeaderText(UserInfo.editable_texts.delivery_client_contact);
      setSubmitButtonText(UserInfo.editable_texts.confirm_order_button);
      setOpenHoursInformationText(
        UserInfo.editable_texts.restaurant_open_hours
      );
      setG_A_ID(UserInfo.editable_texts.google_analistic_ID);
      setFCM_Server_key(UserInfo.editable_texts.FCM_Server_key);
      setSocialMediaHeaderText(UserInfo.editable_texts.visit_socials);
      setAcceptOrderHeaderText(UserInfo.editable_texts.order_accepted_title);
      setAcceptOrderComunicatText(
        UserInfo.editable_texts.online_order_accepted_text
      );
      setDenyOrderHeaderText(UserInfo.editable_texts.order_error_title);
      setDenyOrdedComunicatText(
        UserInfo.editable_texts.online_order_error_text
      );
      setSMSText(UserInfo.editable_texts.sms_order_notification);
      setInformationTitle(UserInfo.editable_texts.information_title);
      setInformationText(UserInfo.editable_texts.information_text);
    }
  }, [UserInfo]);

  const setWebsiteData = () => {
    let json = {
      editable_texts: {
        header_text_1: HeaderText1,
        header_text_2: HeaderText2,
        meal_sizes_button: ShowMoreOptionsButtonText,
        sms_order_notification: SMSText,
        order_summary: HeaderConfirmSectionText,
        my_order_label: MyOrderHeaderText,
        promo_code_label: CodeInpotPleaceholder,
        comment_to_order: ClientTextPleaceholder,
        delivery_address_header: DeliveryAdresHeaderText,
        delivery_client_contact: ContactHeaderText,
        confirm_order_button: SubmitButtonText,
        restaurant_open_hours: OpenHoursInformationText,
        visit_socials: SocialMediaHeaderText,
        order_accepted_title: AcceptOrderHeaderText,
        online_order_accepted_text: AcceptOrderComunicatText,
        order_error_title: DenyOrderHeaderText,
        online_order_error_text: DenyOrdedComunicatText,
        restaurant_auto_closed_alert: AlertCloseText,
        information_title: InformationTitle,
        information_text: InformationText,
        FCM_Server_key: FCM_Server_key,
        google_analistic_ID: G_A_ID,
        facebook_link: FBLink,
        instagram_link: InstLink,
      },
    };
    setActive(false);
    setText("Czekaj");
    axios
      .post(`${URL}/set_website_data.php`, json, {
        "Content-Type": "application/json",
      })
      .then((response) => {
        //console.log(response);

        if (response.status === 200) {
          setActive(true);
          setText("Zapisz zmiany");
          setComunicateText("Zapisano zmiany");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        //console.log(err.request);
        //console.log("Error", err.message);
        setComunicateText("Cos poszło nie tak");
      });
  };
  return (
    <>
      <HeaderNew />
      <div id="content-wrapper" className="d-flex flex-column bg-white">
        <div id="content">
          <SettingsNavNew
            tabs={[
              { name: "Strefy dostaw", path: "/customize/delivery" },
              { name: "Dane firmy", path: "/customize/companyinfo" },
              { name: "Alerty", path: "/customize/alerts" },
              { name: "Wygląd", path: "/customize/style" },
              { name: "Dodatkowe teksty", path: "/customize/texts" },
              { name: "Legenda", path: "/customize/icons" },
            ]}
          />
          <NavNew />
          <div className="max-w-7xl mx-auto ">
            <div className="pl-5 pt-1 pr-5  ">
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Nagłówek
                </p>
              </div>
              <label className="mb-0">Hasło reklamowe</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={HeaderText1}
                  onChange={(e) => {
                    setHeaderText1(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0"> Dodatkowe hasło reklamowe</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  value={HeaderText2}
                  onChange={(e) => {
                    setHeaderText2(e.target.value);
                  }}
                />
              </div>
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Stopka
                </p>
              </div>
              <label className="mb-0">Nagłówek sekcji social media</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={SocialMediaHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setSocialMediaHeaderText(e.target.value);
                  }}
                />
              </div>
              <label className="mb-0">Nagłówek sekcji kontakt</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={ContactHeaderText}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  onChange={(e) => {
                    setContactHeaderText(e.target.value);
                  }}
                />
              </div>
              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  Social Media
                </p>
              </div>

              <label className="mb-0">Adres URL Facebook</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={FBLink}
                  onChange={(e) => {
                    setFBLink(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
              <label className="mb-0"> Adres URL Instagram</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={InstLink}
                  onChange={(e) => {
                    setInstLink(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>

              <div className=" mt-2">
                <p className="text-darkgray font-bold text-xl pt-3 font-weight: 600 font-i">
                  SEO
                </p>
              </div>

              <label className="mb-0">Google Analitics ID</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={G_A_ID}
                  onChange={(e) => {
                    setG_A_ID(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
              <label className="mb-0">FCM server key</label>
              <div class="mb-3 relative rounded-md shadow-sm ">
                <input
                  type="text"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 px-3 sm:text-sm border-borderGray rounded-md"
                  placeholder=""
                  value={FCM_Server_key}
                  onChange={(e) => {
                    setFCM_Server_key(e.target.value);
                  }}
                  id="basic-url"
                  aria-describedby="basic-addon3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="  fixed bottom-1 right-2  w-1360 mb-4 ">
          <button
            type="button "
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo hover:bg-darkIndigo focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setWebsiteData()}
            disabled={!Active}
          >
            {Text}
          </button>
        </div>
      </div>
    </>
  );
};

export default Texts;
